import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { TableGroupDetailType, TableGroupType } from 'src/@types/v2/tablegroup';

// ----------------------------------------------------------------------

type TableGroupStateType = {
  error: boolean;
  loading: {
    main: boolean;
    activation: boolean;
  };
  tableGroups: TableGroupType[];
  currentTableGroup: TableGroupDetailType | null;
};

const initialState: TableGroupStateType = {
  error: false,
  loading: {
    main: false,
    activation: false,
  },
  tableGroups: [],
  currentTableGroup: null,
};

const slice = createSlice({
  name: 'table-groups',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = true;
          break;
        case 'activation':
          state.loading.activation = true;
          break;
        default:
          break;
      }
    },

    // STOP LOADING
    stopLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = false;
          break;
        case 'activation':
          state.loading.activation = false;
          break;
        default:
          break;
      }
    },

    hasError(state, action) {
      state.error = action.payload;
    },

    // SET ORDER DETAIL DATA
    setTableGroups: (state, action) => {
      state.tableGroups = action.payload;
      state.loading.main = false;
      state.loading.activation = false;
    },

    // SET ORDER HISTORY
    setTableGroup(state, action) {
      state.currentTableGroup = action.payload;
      state.loading.main = false;
      state.loading.activation = false;
    },
  },
});

export const useTableGroups = (): TableGroupStateType =>
  useSelector((RootState: { tableGroups: TableGroupStateType }) => RootState.tableGroups);

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, stopLoading, setTableGroup, setTableGroups } = slice.actions;
