import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { GiftcardType } from '../../@types/v2/giftcard';

// ----------------------------------------------------------------------

type GiftcardStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: GiftcardType[];
  giftcard: GiftcardType | null;
};

const initialState: GiftcardStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [] as GiftcardType[],
  giftcard: null as GiftcardType | null,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'giftcards',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET ALL GIFTCARD
    setGiftcardListData(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    // GET GIFTCARD SINGLE
    setGiftcardData(state, action) {
      state.giftcard = action.payload;
      state.isLoading = false;
    },
    // SORT GIFTCARD
    sortByGiftcard(state, action) {
      state.sortBy = action.payload;
    },
  },
});

export const useGiftcard = (): GiftcardStateType =>
  useSelector((RootState: { giftcards: GiftcardStateType }) => RootState.giftcards);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  setGiftcardListData,
  setGiftcardData,
  sortByGiftcard,
} = slice.actions;
