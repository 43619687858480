import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { TagType } from 'src/@types/v2/tags';

// ----------------------------------------------------------------------

type TagsStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: TagType[];
  currentTag: TagType;
};

const initialState: TagsStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  currentTag: {} as TagType,
  data: [] as TagType[],
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET Tags
    getTagsSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    // SORT TAG
    sortByTag(state, action) {
      state.sortBy = action.payload;
    },
    // MERGE DEALS
    mergeTagsSuccess(state, action) {
      state.isLoading = false;
      state.data = [...state.data, action.payload];
    },
    setCurrentTag(state, action) {
      state.isLoading = false;
      state.currentTag = action.payload;
    },
  },
});

export const useTags = (): TagsStateType =>
  useSelector((RootState: { tags: TagsStateType }) => RootState.tags);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  getTagsSuccess,
  mergeTagsSuccess,
  setCurrentTag,
  sortByTag,
} = slice.actions;
