// TO-DO: Unify all printer-related contexts here
import { useEffect, useState, createContext, ReactNode, useRef, useMemo } from 'react';
import io, { Socket } from 'socket.io-client';
import * as Sentry from '@sentry/nextjs';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useSnackbar } from '@components/snackbar';
import { usePropelAuthContext } from '../auth/useAuthContext';
import { OrderPrintOnDraw, generateLabel } from '../utils/printing';
import type { OrderType } from '../@types/v2/orders/list';

type ProviderProps = {
  children: ReactNode;
};

type PrintMode = 'ticket' | 'label' | 'both';

type ContextProps = {
  automaticPrinting: boolean;
  autoprintOnReady: boolean;
  printMode: PrintMode;
  availableModes: PrintMode[];
  toggleAutomaticPrinting: () => void;
  toggleAutoprintOnReady: () => void;
  changePrintMode: (mode: PrintMode) => void;
  socket: any;
  printConfig: () => void;
  printImage: (data: Blob | string) => void;
  printLabel: (order: OrderType) => Promise<boolean>;
  printDebug: () => void;
  toggleKitchenPage: () => void;
};

const initialState: ContextProps = {
  automaticPrinting: false,
  autoprintOnReady: false,
  printMode: 'ticket',
  availableModes: ['ticket', 'label', 'both'],
  toggleAutomaticPrinting: () => {},
  toggleAutoprintOnReady: () => {},
  changePrintMode: (mode: PrintMode) => {},
  socket: null,
  printConfig: () => {},
  printImage: (data: Blob | string) => {},
  printLabel: async (order: OrderType) => false,
  printDebug: () => {},
  toggleKitchenPage: () => {},
};

export const PrinterContext = createContext(initialState);

export const PrinterProvider = ({ children }: ProviderProps) => {
  const { isAuthenticated } = usePropelAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [errorStore, setErrorStore] = useState<any[]>([]);
  const [isKitchenPage, setIsKitchenPage] = useState<boolean>(false);

  // growthbook feature flags
  const LABEL_PRINT_SKU = useFeatureIsOn('label-print-sku');

  // Zebra Browser Print suite
  const [zebraPrinter, setZebraPrinter] = useState<Zebra.Printer | null>(null);
  const [printMode, setPrintMode] = useState<PrintMode>(
    typeof window !== 'undefined' && localStorage.getItem('printMode') !== null
      ? (localStorage.getItem('printMode') as PrintMode)
      : initialState.printMode
  );

  const connectPrinter = (status: 'init' | 'reconnecting') => {
    // TO-DO
  };

  const toggleKitchenPage = () => {
    setIsKitchenPage((prev) => !prev);
  };

  const handleError = (error: any, message?: string, order?: OrderType) => {
    if (!isKitchenPage) {
      // if not kitchen page, push error to errorStore
      setErrorStore((prev) => [...prev, { error, message, order }]);
      return;
    }

    console.error(`${message}`, error || new Error(message));

    if (order) {
      console.error('[Zebra][PRINT] Error printing for order: ', order.order_number);
      console.error('[Zebra][PRINT] Error at branch: ', order.branch.business_name);
    }

    if (error === null) {
      Sentry.captureException(new Error(message));
    } else {
      Sentry.captureException(error);
    }

    enqueueSnackbar(message || 'Error', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  };

  // this useEffect will run each time kitchen page is accessed, dumping errorStore content
  useEffect(() => {
    if (isKitchenPage && errorStore.length > 0) {
      errorStore.forEach((error) => {
        handleError(error.error, error.message, error.order);
      });

      setErrorStore([]);
    }
  }, [isKitchenPage]);

  // this useEffect will run only once, on window load
  useEffect(() => {
    const { BrowserPrint } = window;

    // addEventListener('error', (event) => {
    //   console.error('[Zebra] Error: ', event);
    // });

    BrowserPrint.getDefaultDevice(
      'printer',
      (device) => {
        if (device) {
          console.log('[Zebra] Default printer: ', device);
          const printer = new window.Zebra.Printer(device);

          setZebraPrinter(printer);
        } else {
          handleError(null, '[Zebra][INIT] No default printer found');
        }
      },
      (error) => {
        handleError(error, '[Zebra][INIT] Error getting default printer');
      }
    );
  }, []);

  // this useEffect will run on each printer connect
  useEffect(() => {
    if (zebraPrinter) {
      console.log('[Zebra][INIT] Printer connected, checking status...');

      zebraPrinter
        .isPrinterReady()
        .then(() => {
          console.log('[Zebra][INIT] Printer status obtained...');

          zebraPrinter.getStatus(
            (status) => {
              console.log('[Zebra] Printer status: ', status);
              enqueueSnackbar('[Zebra] Printer connected', {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              });
            },
            (error) => {
              handleError(error, '[Zebra][INIT] Error getting printer status');
            }
          );
        })
        .catch((error: any) => {
          handleError(error, '[Zebra][INIT] Printer readiness check error');

          printerGetStatus();
        });
    }
  }, [zebraPrinter]);

  const getLocalDevices = () => {
    const { BrowserPrint } = window;

    BrowserPrint.getLocalDevices(
      (res: any) => {
        console.log(res);
      },
      (err: any) => {
        console.error(err);
      }
    );
  };

  // get configuration
  const printerGetStatus = () => {
    if (zebraPrinter) {
      zebraPrinter.getStatus(
        (status) => {
          console.log(status);
        },
        (error) => {
          handleError(error, '[Zebra] Error getting printer status');
        }
      );
    } else {
      handleError(null, '[Zebra] No printer connected');
    }
  };

  // print configuration label
  const printConfig = () => {
    if (zebraPrinter) {
      zebraPrinter
        .isPrinterReady()
        .then(() => zebraPrinter.query('~wc'))
        .catch((error: any) => {
          handleError(error, '[Zebra] Error printing configuration label');
        });
    } else {
      handleError(null, '[Zebra] No printer connected');
    }
  };

  // not used
  const printImage = (data: Blob | string) => {
    if (zebraPrinter) {
      zebraPrinter
        .isPrinterReady()
        .then(() =>
          zebraPrinter.printImageAsLabel(
            data,
            {},
            (response: any) => {
              console.log('[Zebra] Print image response: ', response);
            },
            (error: any) => {
              console.error('[Zebra] Error printing image: ', error);
            }
          )
        )
        .catch((error: any) => {
          console.error('[Zebra] Error printing image: ', error);
        });
    } else {
      handleError(null, '[Zebra] No printer connected');
    }
  };

  const printLabel = async (order: OrderType) => {
    let tempItemStore = [...order.items];

    const excluded_product_ids: string[] = [
      '6561907a53ef17c12e680cff', // Delivery product id (Pizzan)
    ];

    // remove excluded items
    tempItemStore = tempItemStore.filter((item) => !excluded_product_ids.includes(item.product_id));

    if (tempItemStore.some((item) => item.type === 'deals' || item.type === 'custom_deals')) {
      tempItemStore.forEach((dealItem) => {
        if (dealItem.type === 'deals' || dealItem.type === 'custom_deals') {
          // move deal items into order.items
          dealItem.menus.forEach((item) => {
            item.menu_items.forEach((curItem) => {
              // handle dealItem with multiple quantities
              for (let i = 0; i < dealItem.quantity; i += 1) {
                tempItemStore.push(curItem);
              }
            });
          });
        }

        // remove deal items from order.items
        tempItemStore = tempItemStore.filter(
          (item) => item.type !== 'deals' && item.type !== 'custom_deals'
        );
      });
    }

    // check for items with multiple quantities
    tempItemStore.forEach((item) => {
      if (item.quantity > 1) {
        // duplicate the item and add it to order.items
        for (let i = 1; i < item.quantity; i += 1) {
          tempItemStore.push(item);
        }
      }
    });

    let buffer = '';

    tempItemStore.forEach((item, index) => {
      const query = generateLabel(
        order,
        item,
        {
          LABEL_PRINT_SKU,
        },
        index,
        tempItemStore.length
      );

      // console.log(query);

      buffer += query;
    });

    if (zebraPrinter) {
      // start timeout timer, throw error after 5 seconds
      const timeout = setTimeout(() => {
        handleError(null, '[Zebra][PRINT] Failed to print: Timeout', order);
        return false;
      }, 5000);

      return zebraPrinter
        .isPrinterReady()
        .then(async () =>
          zebraPrinter
            .query(buffer)
            .then((res) => {
              console.log('[Zebra][PRINT] Label printed for order: ', order.order_number);
              clearTimeout(timeout);
              return true;
            })
            .catch((err) => {
              handleError(err, '[Zebra][PRINT] Error printing label', order);
              clearTimeout(timeout);
              return false;
            })
        )
        .catch((err) => {
          handleError(err, `[Zebra][PRINT] ${err}`, order);
          clearTimeout(timeout);
          return false;
        });
    }
    handleError(null, '[Zebra][PRINT] No printer connected');
    return false;
  };

  const changePrintMode = (mode: PrintMode) => {
    setPrintMode(mode);
    localStorage.setItem('printMode', mode);
  };

  // Printing queue suite
  const [printingQueue, setPrintingQueue] = useState<OrderType[]>([]);
  const [autoprintEnabled, setAutoprintEnabled] = useState<boolean>(
    typeof window !== 'undefined' ? localStorage.getItem('autoprint') === 'true' : false
  );
  const [autoprintOnReadyEnabled, setAutoprintOnReadyEnabled] = useState<boolean>(
    typeof window !== 'undefined' ? localStorage.getItem('autoprintOnReady') === 'true' : false
  );

  useEffect(() => {
    // when printing queue is updated, print the first order
    console.log('[Socket] Printing Queue Updated. Length: ', printingQueue.length);

    if (printingQueue.length > 0) {
      const interval = setTimeout(() => {
        console.log('[Socket] Printing Order: ', printingQueue[0].order_number);

        enqueueSnackbar('New order arrived', {
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          action: (key: any) => autoprintEnabled && <OrderPrintOnDraw order={printingQueue[0]} />,
        });

        // remove the first order from printing queue
        setPrintingQueue((prev) => prev.slice(1));
      }, 1500);

      return () => {
        clearTimeout(interval);
      };
    }
    return () => {};
  }, [printingQueue]);

  const toggleAutomaticPrinting = () => {
    setAutoprintEnabled(!autoprintEnabled);
    localStorage.setItem('autoprint', (!autoprintEnabled).toString());
  };

  const toggleAutoprintOnReady = () => {
    setAutoprintOnReadyEnabled(!autoprintOnReadyEnabled);
    localStorage.setItem('autoprintOnReady', (!autoprintOnReadyEnabled).toString());
  };

  // Socket communications suite
  // TO-DO: Replace websocket with SSE
  const orders = useRef<Socket>();

  const connect = () => {
    const accessToken = localStorage.getItem('accessToken');

    orders.current = io(
      `${process.env.NEXT_PUBLIC_API_ENDPOINT || 'https://upsell-api-v2.herokuapp.com'}/v2/orders`,
      {
        path: '/v2/socket',
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  const disconnect = () => {
    if (orders.current) {
      orders.current.disconnect();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      // connect();

      if (orders.current) {
        // orders.current.on('connect', () => {
        //   console.log('[Socket] Connected');
        // });
        // orders.current.on('disconnect', () => {
        //   console.log('[Socket] Disconnected');
        // });
        // orders.current.on('order-new', (data) => {
        //   // console.log('[Socket] New Order', data);
        //   // push to printing queue
        //   setPrintingQueue((prev) => [...prev, data]);
        //   // add to redux
        //   dispatch(addOrder(data));
        //   // TODO: Append notification to redux
        // });
        // orders.current.on('order-new', (data) => {
        //   // console.log('[Socket] New Order', data);
        //   // push to printing queue
        //   // setPrintingQueue((prev) => [...prev, data]);
        //   // TODO: Append order from socket to redux
        //   // TODO: Append notification to redux
        // });
        // orders.current.on('ticket-new', (data) => {
        //   dispatch(addTicket(data));
        // });
        // orders.current.on('refresh-clients', (data) => {
        //   if (data === 'dashboard') {
        //     window.location.reload();
        //   }
        // });
        // Perhaps add option to override printing queue to use this channel just in case?
        // orders.current.on('order-new-store', (data) => {
        //   console.log('[Socket] New Order GLOBAL', data);
        // });
        // orders.current.on('order-debug', (data) => {
        //   console.log('[Socket] Order Debug Received', data);
        // });
        // orders.current.on('order-updated', (data) => {
        //   // console.log('[Socket] Order Updated', data);
        //   // TODO: Handle order update from socket to redux
        //   console.log('[Socket] Order Updated', data);
        //   dispatch(updateOrder(data));
        // });
        // orders.current.on('order-cancelled', (data) => {
        //   dispatch(updateTicketState(data.ticket._id, 'cancelled'));
        // });
        // orders.current.on('ticket-updated', (data) => {
        //   // console.log('[Socket] ticket Updated', data);
        //   dispatch(updateTicketState(data._id, data.ticket_status));
        // });
        // orders.current.on('ticket-check-updated', (data) => {
        //   // console.log('[Socket] ticket Updated', data);
        //   dispatch(checkItem(data.order_id, data.item_id, data.checked));
        // });
        // orders.current.on('settings-updated', (data) => {
        //   dispatch(getBranches());
        // });
      }
    }

    return () => {
      disconnect();
    };
  }, [isAuthenticated]);

  const contextValue = useMemo(
    () => ({
      automaticPrinting: autoprintEnabled,
      autoprintOnReady: autoprintOnReadyEnabled,
      toggleKitchenPage,
      printMode,
      toggleAutomaticPrinting,
      toggleAutoprintOnReady,
      changePrintMode,
      socket: orders,
      printConfig,
      printImage,
      printLabel,
      printDebug: printerGetStatus,
      availableModes: initialState.availableModes,
    }),
    [
      autoprintEnabled,
      autoprintOnReadyEnabled,
      toggleKitchenPage,
      printMode,
      toggleAutomaticPrinting,
      toggleAutoprintOnReady,
      changePrintMode,
      orders,
      printConfig,
      printImage,
      printLabel,
      printerGetStatus,
    ]
  );

  return <PrinterContext.Provider value={contextValue}>{children}</PrinterContext.Provider>;
};
