import { createContext, ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react';
import axios from 'axios';
import localStorageAvailable from '@utils/template/localStorageAvailable';
import { JWTContextType } from '../types/auth';
import { getRoleData, isValidToken, setSession } from '../utils';
import { initialState, reducer, Types } from './reducer';

// We need to create this client here because we don't
// have access to the AxiosContext yet since it requires
// AuthProvider itself
const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
});

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  //   const axiosInstance = useAxios();

  const [state, dispatch] = useReducer(reducer, initialState);
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axiosInstance.get('/v2/users', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        const user = response.data.data;
        const role = getRoleData(user);

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
            role,
            accessToken,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
            role: null,
            accessToken: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
          role: null,
          accessToken: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axiosInstance.get('/v2/auth', {
      auth: {
        username: email,
        password,
      },
    });

    const user = response.data.data;
    const role = getRoleData(user);
    const accessToken = response.headers['x-token'].toString();

    setSession(accessToken);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
        role,
        accessToken,
      },
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axiosInstance.post('/v2/users', {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    });

    const user = response.data.data;
    const role = getRoleData(user);
    const accessToken = response.headers['x-token'].toString();

    setSession(accessToken);

    dispatch({
      type: Types.REGISTER,
      payload: {
        user,
        role,
        accessToken,
      },
    });
  };

  const logout = async () => {
    setSession(null);

    dispatch({ type: Types.LOGOUT });
  };

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      api_version: state.api_version,
      role: state.role,
      accessToken: state.accessToken,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
