import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, useSelector } from '@redux/store';
import { calcCouponDiscount } from '@utils/orders/calculatePrice';
import { CouponTypeClass } from 'src/@types/v2/coupon';
import { CreateOrderFormProps, CartItemOrder } from 'src/validations/orders/create-order';

// ----------------------------------------------------------------------
interface CartState {
  hasError: boolean;
  form: CreateOrderFormProps;
}

const initialState: CartState = {
  hasError: false,
  form: {
    store_id: '',
    prioritized: false,
    customer_id: '',
    additional_cost: [],
    order_note: '',
    customer_note: '',
    order_method: '',
    payment_id: '',
    payment_method_title: '',
    currency: '',
    order_status: 'processing',
    selectBranch: 'auto',
    billing: null,
    items: [],
    order_type: null,
    custom_discount: {
      active: false,
      type: 'fixed',
      value: 0,
    },
    delivery_details: {
      address: '',
      detail_address: '',
      postcode: '',
      note: '',
      branch: {
        _id: '',
        name: '',
        delivery_fee: 0,
      },
    },
    pickup_details: {
      location: '',
      branch_id: '',
    },
    shipping_details: {
      address: '',
      country: '',
      city: '',
      postcode: '',
      detail_address: '',
    },
    scheduled_data: { status: false, date: new Date() },
    vat_rate: 0,
    is_paid: false,
  },
};

// ----------------------------------------------------------------------

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    hasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },
    addItemToCart: (state, action: PayloadAction<CartItemOrder>) => {
      const addToCart = (items: CartItemOrder[], product: CartItemOrder) => {
        const isProductInCart = items.find((item) => item.ref === product.ref);

        if (!isProductInCart) {
          return [...items, product];
        }

        if (isProductInCart) {
          return items.map((item) => {
            if (item.ref === product.ref) {
              item = product;
            }
            return item;
          });
        }

        return [...items, product];
      };
      state.form.items = addToCart(state.form.items, action.payload);
    },
    applyItems: (state, action: PayloadAction<CartItemOrder[]>) => {
      state.form.items = action.payload;
    },
    deleteItem: (
      state,
      action: PayloadAction<{
        item: CartItemOrder;
      }>
    ) => {
      const { items } = state.form;
      const { item: itemToDelete } = action.payload;

      const index = items.findIndex((i) => i.ref === itemToDelete.ref);

      if (index === -1) {
        return;
      }

      const newItems = [...items];
      newItems.splice(index, 1);
      state.form.items = newItems;
    },
    increaseQuantity: (
      state,
      action: PayloadAction<{
        item: CartItemOrder;
      }>
    ) => {
      const { items } = state.form;
      const { item } = action.payload;

      const index = items.findIndex((i) => i.ref === item.ref);

      if (index === -1) {
        return;
      }

      const newItems = [...items];

      newItems[index].quantity += 1;

      state.form.items = newItems;
    },
    decreaseQuantity: (
      state,
      action: PayloadAction<{
        item: CartItemOrder;
      }>
    ) => {
      const { items } = state.form;
      const { item } = action.payload;

      const index = items.findIndex((i) => i.ref === item.ref);

      if (index === -1) {
        return;
      }

      const newItems = [...items];

      if (newItems[index].quantity === 1) {
        newItems.splice(index, 1);
      } else {
        newItems[index].quantity -= 1;
      }

      state.form.items = newItems;
    },
    setQuantity: (
      state,
      action: PayloadAction<{
        item: CartItemOrder;
        quantity: number;
      }>
    ) => {
      const { items } = state.form;
      const { item, quantity } = action.payload;

      const index = items.findIndex((i) => i.ref === item.ref);

      if (index === -1) {
        return;
      }

      const newItems = [...items];

      if (quantity === 0) {
        newItems.splice(index, 1);
      } else {
        newItems[index].quantity = parseInt(quantity.toString(), 10);
      }

      state.form.items = newItems;
    },
    setPickupDetails: (
      state,
      action: PayloadAction<{
        pickup_details: CreateOrderFormProps['pickup_details'];
        vat_rate: CreateOrderFormProps['vat_rate'];
      }>
    ) => {
      state.form.order_type = 'pickup';
      state.form.pickup_details = action.payload.pickup_details;
      state.form.vat_rate = action.payload.vat_rate;
    },
    setDeliveryDetails: (
      state,
      action: PayloadAction<{
        delivery_details: CreateOrderFormProps['delivery_details'];
        vat_rate: CreateOrderFormProps['vat_rate'];
      }>
    ) => {
      state.form.order_type = 'delivery';
      state.form.delivery_details = action.payload.delivery_details;
      state.form.vat_rate = action.payload.vat_rate;
    },
    setDeliveryBranch: (
      state,
      action: PayloadAction<{
        branch: CreateOrderFormProps['delivery_details']['branch'];
      }>
    ) => {
      state.form.delivery_details.branch._id = action.payload.branch._id;
      state.form.delivery_details.branch.name = action.payload.branch.name;
      state.form.delivery_details.branch.delivery_fee = action.payload.branch.delivery_fee;
    },
    setBillingCustomer: (
      state,
      action: PayloadAction<{
        billing: CreateOrderFormProps['billing'];
        customer_id: CreateOrderFormProps['customer_id'];
      }>
    ) => {
      state.form.billing = action.payload.billing;
      state.form.customer_id = action.payload.customer_id;
    },
    setOrderStatus: (state, action: PayloadAction<CreateOrderFormProps['order_status']>) => {
      state.form.order_status = action.payload;
    },
    setVATRate: (state, action: PayloadAction<CreateOrderFormProps['vat_rate']>) => {
      state.form.vat_rate = action.payload;
    },
    setIsPaid: (
      state,
      action: PayloadAction<{
        is_paid: CreateOrderFormProps['is_paid'];
        payment_id: CreateOrderFormProps['payment_id'];
        payment_method_title: CreateOrderFormProps['payment_method_title'];
      }>
    ) => {
      state.form.is_paid = action.payload.is_paid;
      state.form.payment_id = action.payload.payment_id;
      state.form.payment_method_title = action.payload.payment_method_title;
    },
    setOrderNote: (state, action: PayloadAction<CreateOrderFormProps['order_note']>) => {
      state.form.order_note = action.payload;
    },
    setCustomerNote: (state, action: PayloadAction<CreateOrderFormProps['customer_note']>) => {
      state.form.customer_note = action.payload;
    },
    setPriority: (state, action: PayloadAction<CreateOrderFormProps['prioritized']>) => {
      state.form.prioritized = action.payload;
    },
    setCoupon: (state, action: PayloadAction<CreateOrderFormProps['coupon']>) => {
      state.form.coupon = action.payload;
    },
    setSelectBranchMethod: (state, action: PayloadAction<CreateOrderFormProps['selectBranch']>) => {
      state.form.selectBranch = action.payload;
    },
    setStoreId: (state, action: PayloadAction<CreateOrderFormProps['store_id']>) => {
      state.form.store_id = action.payload;
    },
    setFixedDiscount: (state, action: PayloadAction<CreateOrderFormProps['custom_discount']>) => {
      state.form.custom_discount = action.payload;
    },
    setPrice: (
      state,
      action: PayloadAction<{
        item: CartItemOrder;
        price: number;
      }>
    ) => {
      const { items } = state.form;
      const { item, price } = action.payload;

      const index = items.findIndex((i) => i.ref === item.ref);

      if (index === -1) {
        return;
      }

      const newItems = [...items];

      newItems[index].price = parseInt(price.toString(), 10);
      newItems[index].custom_price = true;

      state.form.items = newItems;
    },
    setScheduledStatus: (state, action: PayloadAction<boolean>) => {
      state.form.scheduled_data.status = action.payload;
    },
    setScheduledDate: (state, action: PayloadAction<Date | undefined>) => {
      state.form.scheduled_data.date = action.payload;
    },
    reset: () => initialState,
  },
});

export const useCart = () => useSelector((state: RootState) => state.cart);

export const {
  hasError,
  addItemToCart,
  increaseQuantity,
  decreaseQuantity,
  deleteItem,
  setPickupDetails,
  setDeliveryDetails,
  setDeliveryBranch,
  applyItems,
  setQuantity,
  setBillingCustomer,
  setOrderStatus,
  setIsPaid,
  setOrderNote,
  reset,
  setCoupon,
  setCustomerNote,
  setPriority,
  setSelectBranchMethod,
  setFixedDiscount,
  setPrice,
  setStoreId,
  setScheduledStatus,
  setScheduledDate,
} = cartSlice.actions;
export default cartSlice.reducer;

/* HOOK: to get the pricing of the cart  */
export const useCartPricing = () => {
  const state = useSelector((curState: RootState) => curState.cart);

  const generateSubTotal = (items: CreateOrderFormProps['items']) => {
    let total = 0;
    items.forEach((item) => {
      total += item.quantity * item.price;
    });
    return total;
  };

  const generateCartTotalPrice = (
    items: CreateOrderFormProps['items'],
    vat_rate: CreateOrderFormProps['vat_rate'],
    delivery_fee: number,
    coupon?: CouponTypeClass
  ) => {
    const { custom_discount } = state.form;
    const subTotal = generateSubTotal(items);
    let discountValue = 0;
    if (state.form.order_type) {
      if (coupon) {
        discountValue = calcCouponDiscount(items, coupon, state.form.order_type);
      } else {
        discountValue = calcCouponDiscount(items, null, state.form.order_type, custom_discount);
      }
    }

    const total = Math.round(subTotal + delivery_fee - discountValue);

    // NOTE: Use VAT in Total Calculation. Currently not used, since the product price already included VAT
    // return Math.round(subTotal + subTotal * (vat_rate / 100)) + delivery_fee - discountValue;

    // NOTE: Currently use total price calculation without additional VAT
    return total > 0 ? total : 0;
  };

  return {
    cartItemsSubTotal: generateSubTotal(state.form.items),
    cartTotalPrice: generateCartTotalPrice(
      state.form.items,
      state.form.vat_rate,
      state.form.delivery_details.branch?.delivery_fee ?? 0,
      state.form.coupon?.coupon_type
    ),
  };
};
