import { VariableType } from 'src/validations/orders/create-order';
import { VariationType } from 'src/@types/v2/products';
import { convertVariations, generateVariations } from '../variation';
import { arraysEqual } from './deepEqual';

/* FUNC: to get Variables data, for the single pizza */
export const getSingleData = (data: any) => {
  if (!data) return [];

  const forbiddenVariables = inferForbiddenVariables(data);

  return inactiveLabelling(data.variables, forbiddenVariables);
};

/* FUNC: to get an Intersection from two sets of data. for the available Variables in split pizza  */
export const getSplitData = (leftData: any, rightData: any) => {
  if (!rightData && !leftData) return [];
  if (!rightData) return getSingleData(leftData);
  if (!leftData) return getSingleData(rightData);

  const combined = leftData.variables
    .map((varbl: any) => ({
      left: varbl,
      right: rightData.variables.find((x: any) => x.variable._id === varbl.variable._id),
    }))
    .filter((x: any) => x.right)
    .map((both: any) => ({
      ...both.left,
      attributes: both.left.attributes
        .map((attr: any) => ({
          ...attr,
          rightAttrId: both.right.attributes.find(
            (x: any) => x.attribute._id === attr.attribute._id
          )?._id,
        }))
        .filter((x: any) => x.rightAttrId),
    }));

  const forbiddenCombinationsLeft: VariableType[][] = inferForbiddenVariables(leftData);

  const forbiddenCombinationsRight: VariableType[][] = inferForbiddenVariables(rightData);

  const forbiddenCombinations = [
    ...forbiddenCombinationsLeft,
    ...forbiddenCombinationsRight,
  ].filter(
    (combination, index, self) =>
      index ===
      self.findIndex((t) =>
        t.every((v) =>
          combination.find((x) => x.variable === v.variable && x.attribute === v.attribute)
        )
      )
  );

  return inactiveLabelling(combined, forbiddenCombinations);
};

/* FUNC: to give an extra property of `antiVariables`. List of other variables that can not be used along with */
export const inactiveLabelling = (variablesData: any, inactiveCombinations: VariableType[][]) =>
  variablesData.map((varbl: any) => ({
    ...varbl,
    attributes: varbl.attributes.map((attr: any) => {
      const antiVariables = inactiveCombinations
        .map((inactiveCombination: VariableType[]) => {
          const isRelated = !!inactiveCombination.find(
            (x: VariableType) =>
              x.variable === varbl.variable._id && x.attribute === attr.attribute._id
          );

          const prohibitions = inactiveCombination.filter(
            (x: VariableType) =>
              x.variable !== varbl.variable._id && x.attribute !== attr.attribute._id
          );

          return isRelated ? prohibitions : [];
        })
        .filter((y: VariableType[]) => y.length)
        .flat();

      return {
        ...attr,
        antiVariables,
      };
    }),
  }));

export const inferForbiddenVariables = (data: any) => {
  if (!data) return [];

  const inactiveCombinations: VariableType[][] = data.variations
    .filter((variation: VariationType) => !variation.active)
    .map((variation: VariationType) => convertVariations.toVariables(variation));

  if (!data.available_variations) return inactiveCombinations;

  const availableVariables: VariableType[][] = data.available_variations.map(
    (availableVariation: any) =>
      availableVariation.variants.map((variant: any) => ({
        variable: variant.variant,
        attribute: variant.attribute,
      }))
  );

  const unavailableCombinations = data.variations
    .filter((variation: VariationType) => variation.active)
    .map((variation: VariationType) => convertVariations.toVariables(variation))
    .filter(
      (variables: VariableType[]) =>
        !availableVariables.find((availableVariable: VariableType[]) =>
          arraysEqual(variables, availableVariable)
        )
    );

  return [...unavailableCombinations, ...inactiveCombinations];
};

export const decideDefaultVariables = (data: any) => {
  const visibleVariables: VariableType[][] = generateVariations(
    data.variables.map((variable: any) =>
      variable.attributes.map((attribute: any) => ({
        variable: variable.variable._id,
        attribute: attribute.attribute._id,
      }))
    )
  );

  const forbiddenVariables: VariableType[][] = inferForbiddenVariables(data);
  const selectableVariables = visibleVariables.filter(
    (variable) =>
      !forbiddenVariables.find((forbiddenVariable) => arraysEqual(variable, forbiddenVariable))
  );

  const defaultVariable: VariableType[] = convertVariations.toVariables(
    data.variations.find((variation: any) => variation.is_default_variation)
  );

  if (
    defaultVariable &&
    selectableVariables.find((selectableVariable) =>
      arraysEqual(selectableVariable, defaultVariable)
    )
  ) {
    return defaultVariable;
  }
  return selectableVariables[0];
};
