import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CompanyType, CustomerType, CustomerWithOrdersType } from 'src/@types/v2/customers/list';

// ----------------------------------------------------------------------

type CustomerListStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string;
  companies: {
    data: CompanyType[];
    pagination: {
      limit: number;
      page: number;
      total: number;
      hasMoreResults: boolean;
    };
  };
  customers: {
    data: CustomerType[];
    pagination: {
      limit: number;
      page: number;
      total: number;
      hasMoreResults: boolean;
    };
  };
  customersWithOrders: {
    data: CustomerWithOrdersType[];
    pagination: {
      limit: number;
      page: number;
      total: number;
      hasMoreResults: boolean;
    };
  };
};

const initialState: CustomerListStateType = {
  isLoading: true,
  error: false,
  sortBy: 'createdAt-desc',
  companies: {
    data: [] as CompanyType[],
    pagination: {
      limit: 25,
      page: 1,
      total: 0,
      hasMoreResults: false,
    },
  },
  customers: {
    data: [] as CustomerType[],
    pagination: {
      limit: 25,
      page: 1,
      total: 0,
      hasMoreResults: false,
    },
  },
  customersWithOrders: {
    data: [] as CustomerWithOrdersType[],
    pagination: {
      limit: 25,
      page: 1,
      total: 0,
      hasMoreResults: false,
    },
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'customer-list',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // BASIC CUSTOMERS
    setCustomers(state, action) {
      state.customers.data = action.payload;
      state.isLoading = false;
    },

    setCustomersPagination(state, action) {
      state.customers.pagination = action.payload;
    },

    sortByCustomers(state, action) {
      state.sortBy = action.payload;
    },

    // CUSTOMERS WITH ORDERS
    setCustomersWithOrders(state, action) {
      state.isLoading = false;
      state.customersWithOrders.data = action.payload;
    },

    setCustomersWithOrdersPagination(state, action) {
      state.customersWithOrders.pagination = action.payload;
    },

    // COMPANIES
    setCompanies(state, action) {
      state.isLoading = false;
      state.companies.data = action.payload;
    },

    setCompaniesPagination(state, action) {
      state.companies.pagination = action.payload;
    },
  },
});

export const useCustomerList = (): CustomerListStateType =>
  useSelector((RootState: { customerList: CustomerListStateType }) => RootState.customerList);

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  stopLoading,
  // BASIC CUSTOMERS
  setCustomers,
  setCustomersPagination,
  sortByCustomers,
  // CUSTOMERS WITH ORDERS
  setCustomersWithOrders,
  setCustomersWithOrdersPagination,
  // COMPANIES
  setCompanies,
  setCompaniesPagination,
} = slice.actions;
