import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from '@redux/store';
import { inferExtras, inferVariables } from '@utils/orders/infers';
import { calcPricing } from '@utils/orders/calculatePrice';
import {
  decideDefaultVariables,
  getSingleData,
  getSplitData,
} from '@utils/orders/variableProcessor';
import { OutputSide, VariableType } from 'src/validations/orders/create-order';
import { ProductType } from 'src/@types/v2/products';

// ----------------------------------------------------------------------

type position = 'left' | 'right';

type PizzaDialogStateType = {
  sideFocus: 'left' | 'right';
  split_mode: boolean;
  output: {
    left: OutputSide;
    right: OutputSide;
  };
  quantity: number;
  cartRef: string | null;
  dealsSessionMenuRef?: string;
};

const initialState: PizzaDialogStateType = {
  sideFocus: 'left',
  split_mode: false,
  output: {
    left: {
      _id: '',
      _data: null,
      variables: [],
      extras: [],
    },
    right: {
      _id: '',
      _data: null,
      variables: [],
      extras: [],
    },
  },
  quantity: 1,
  cartRef: null,
  dealsSessionMenuRef: '',
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'pizza-order-dialog',
  initialState,
  reducers: {
    selectPizza: (
      state,
      { payload }: PayloadAction<{ output: ProductType; position: position }>
    ) => {
      state.output[payload.position]._data = payload.output;
    },
    reset: () => initialState,
    handleSplitMode: (state, action) => {
      /* FUNC: setSplitMode with some side effects */

      /* From split to single, if left is empty but right is not, move right to left */
      if (!action.payload && !state.output.left._id) state.output.left = state.output.right;

      state.split_mode = action.payload;

      if (action.payload) {
        if (state.output.left._id) {
          if (state.output.right._id) {
            state.sideFocus = 'left';
          } else {
            state.sideFocus = 'right';
          }
        } else {
          state.sideFocus = 'left';
        }
      } else {
        state.sideFocus = 'left';
      }

      /* Right side should always be reset */
      if (action.payload) {
        // state.output.right = state.output.left;
      } else state.output.right = initialState.output.right;
    },
    setSideFocus: (state, { payload }: PayloadAction<position>) => {
      state.sideFocus = payload;
    },
    setInitialValue: (state, { payload }: PayloadAction<any>) => {
      const { data, ref, split_mode = false, quantity = 1 } = payload;

      if (ref) state.cartRef = ref;

      state.output = data;

      state.split_mode = split_mode;

      state.quantity = quantity;
    },
    applyProduct: (state, action) => {
      /* FUNC: Called when pizza changes with a new one, apply the data and templated variables and extras, with side effects related to previous user selection preservation */

      const { side, productData } = action.payload as {
        side: PizzaDialogStateType['sideFocus'];
        productData: any;
      };

      /* FUNC: If to-be-applied pizza cannot preserve the previous selection, then reset the selection for both side */
      const reselect = (groupId: string, value: string) => {
        state.output[counterSide].variables = state.output[counterSide].variables.map((varbl) => {
          if (varbl.variable === groupId)
            return {
              variable: groupId,
              attribute: value,
            };
          return varbl;
        });
      };

      const preserve = (() => decideDefaultVariables(productData))();

      const counterSide = side === 'left' ? 'right' : 'left';
      const counterData = state.output[counterSide]._data;

      const variablesData = counterData
        ? getSplitData(productData, counterData)
        : getSingleData(productData);

      state.output[side] = {
        _id: productData._id,
        _data: productData,
        variables: inferVariables(variablesData, preserve, reselect),
        extras: inferExtras(productData),
      };
    },
    updateVariable: (state, action) => {
      /* FUNC: Called every variable selection changes */

      const { variable, attribute } = action.payload as {
        variable: string;
        attribute: string;
      };

      const getUpdatedVariables = (previous: OutputSide) => {
        const data = (
          state.split_mode
            ? getSplitData(state.output.left._data, state.output.right._data)
            : getSingleData(previous._data)
        )
          .find((vrbl: any) => vrbl.variable._id === variable)
          .attributes.find((attr: any) => attr.attribute._id === attribute);
        const isInactive =
          previous.variables.filter((x) =>
            data.antiVariables.find(
              (y: VariableType) => y.variable === x.variable && y.attribute === x.attribute
            )
          ).length > 0;

        if (!isInactive) {
          return previous.variables.map((current) => {
            if (current.variable === variable) {
              return {
                variable,
                attribute,
              };
            }
            return current;
          });
        }
        const reselected = previous.variables.map((current) => {
          if (current.variable === variable) {
            return {
              variable,
              attribute,
            };
          }
          if (
            data.antiVariables.find(
              (y: VariableType) =>
                y.variable === current.variable && y.attribute === current.attribute
            )
          ) {
            const selectables = getSingleData(previous._data)
              .find((vrbl: any) => vrbl.variable._id === current.variable)
              .attributes.map((attr: any) => attr.attribute._id)
              .filter(
                (attr: string) =>
                  !data.antiVariables.find(
                    (y: VariableType) => y.variable === current.variable && y.attribute === attr
                  )
              );

            if (selectables.length > 0)
              return {
                variable: current.variable,
                attribute: selectables[0],
              };
            return null;
          }
          return current;
        });

        if (!reselected.includes(null)) return reselected as VariableType[];
        return previous.variables;
      };

      state.output.left.variables = getUpdatedVariables(state.output.left);

      /* In split pizza, update is done to both side  */
      if (state.split_mode) {
        state.output.right.variables = getUpdatedVariables(state.output.right);
      }
    },
    updateExtra: (state, action) => {
      /* FUNC: Called every time extras selection changes */
      const { extra, item, doubled, quantity } = action.payload as {
        extra: string;
        item: string;
        doubled: boolean;
        quantity: number;
      };

      const value =
        state.output[state.sideFocus].extras
          .find((x: any) => x._id === extra)
          ?.items.find((y: any) => y._id === item) || null;

      const isSelected = !!value;
      const isDoubled = value?.doubled;
      const currentQuantity = value?.quantity ?? 1;

      /* FUNC: 3 types of operations, select to select, unselect to unselect, handleDouble to toggle the 2x  */
      const operations = {
        select: () => [
          ...state.output[state.sideFocus].extras.filter((x) => x._id !== extra),
          {
            _id: extra,
            items: [
              ...(state.output[state.sideFocus].extras.find((x) => x._id === extra)?.items || []),
              { _id: item, doubled, quantity },
            ],
          },
        ],
        unselect: () => [
          ...state.output[state.sideFocus].extras.map((x) => {
            if (x._id === extra) {
              return {
                _id: extra,
                items: x.items.filter((itm: any) => itm._id !== item),
              };
            }
            return x;
          }),
        ],
        handleDoubled: () => [
          ...state.output[state.sideFocus].extras.map((x) => {
            if (x._id === extra) {
              return {
                _id: extra,
                items: x.items.map((itm: any) => {
                  if (itm._id === item) {
                    return {
                      _id: itm._id,
                      doubled: !itm.doubled,
                    };
                  }
                  return itm;
                }),
              };
            }
            return x;
          }),
        ],
        increaseQuantity: () => [
          ...state.output[state.sideFocus].extras.map((x) => {
            if (x._id === extra) {
              return {
                _id: extra,
                items: x.items.map((itm: any) => {
                  if (itm._id === item) {
                    return {
                      _id: itm._id,
                      quantity: itm.quantity + 1,
                    };
                  }
                  return itm;
                }),
              };
            }
            return x;
          }),
        ],
        decreaseQuantity: () => [
          ...state.output[state.sideFocus].extras.map((x) => {
            if (x._id === extra) {
              return {
                _id: extra,
                items: x.items.map((itm: any) => {
                  if (itm._id === item) {
                    return {
                      _id: itm._id,
                      quantity: itm.quantity - 1,
                    };
                  }
                  return itm;
                }),
              };
            }
            return x;
          }),
        ],
      };

      let updated;

      if (isSelected) {
        if (quantity === currentQuantity) {
          updated = operations.unselect();
        } else if (quantity < currentQuantity && quantity > 0) {
          updated = operations.decreaseQuantity();
        } else if (quantity > currentQuantity && quantity > 0) {
          updated = operations.increaseQuantity();
        } else {
          updated = operations.unselect();
        }
      } else {
        updated = operations.select();
      }

      if (updated) {
        state.output[state.sideFocus].extras = updated;
      }
    },
    resetExtras: (state, action) => {
      /* FUNC: To reset the extras selection (by group) */
      const groupId = action.payload as string;
      const inferredExtras = inferExtras(state.output[state.sideFocus]._data);

      const updated = inferredExtras.find((extra: any) => extra._id === groupId) || null;

      state.output[state.sideFocus] = {
        ...state.output[state.sideFocus],
        extras: [
          ...state.output[state.sideFocus].extras.filter((extra) => extra._id !== groupId),
          updated,
        ].filter((extra) => extra !== null),
      };
    },
    increaseQuantity: (state) => {
      state.quantity += 1;
    },
    decreaseQuantity: (state) => {
      state.quantity -= 1;
    },
    setDealsSessionMenuRef: (state, action) => {
      state.dealsSessionMenuRef = action.payload;
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setLeft: (state, action) => {
      state.output.left = action.payload;
    },
    setRight: (state, action) => {
      state.output.right = action.payload;
    },
    setSplitMode: (state, action) => {
      /* FUNC: setSplitMode with some side effects */

      /* From split to single, if left is empty but right is not, move right to left */
      if (!action.payload && !state.output.left._id) state.output.left = state.output.right;

      state.split_mode = action.payload;
      if (action.payload) {
        if (state.output.left._id) {
          if (state.output.right._id) {
            state.sideFocus = 'left';
          } else {
            state.sideFocus = 'right';
          }
        } else {
          state.sideFocus = 'left';
        }
      } else {
        state.sideFocus = 'left';
      }

      /* Right side should always be reset */
      if (action.payload) state.output.right = state.output.left;
      else state.output.right = initialState.output.right;
    },
  },
});

export const usePizzaDialog = () => useSelector((store) => store.pizzaOrderDialog);

/* HOOK: to get the price of the current product held in this redux state  */
export const usePricing = (): number => {
  const state = useSelector(
    (Root: { pizzaOrderDialog: PizzaDialogStateType }) => Root.pizzaOrderDialog
  );

  if (state?.split_mode) {
    return calcPricing({
      split_mode: true,
      output: state.output,
      quantity: state.quantity,
    });
  }

  return calcPricing({
    split_mode: false,
    output: state.output.left,
    quantity: state.quantity,
  });
};

// Reducer
export default slice.reducer;

// Actions
export const {
  reset,
  selectPizza,
  handleSplitMode,
  setSideFocus,
  resetExtras,
  updateExtra,
  updateVariable,
  applyProduct,
  increaseQuantity,
  decreaseQuantity,
  setInitialValue,
  setDealsSessionMenuRef,
  setQuantity,
  setLeft,
  setRight,
  setSplitMode,
} = slice.actions;
