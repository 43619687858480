const deepEqual: any = (a: any, b: any) => {
  if (a === b) return true;
  if (a == null || typeof a !== 'object' || b == null || typeof b !== 'object') return false;

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  return keysA.every((key) => keysB.includes(key) && deepEqual(a[key], b[key]));
};

export const arraysEqual = (a: any[], b: any[]) => {
  if (!a || !b) return false;
  if (a.length !== b.length) return false;

  return a.every((item, index) => deepEqual(item, b[index]));
};

export default deepEqual;
