import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { ReactNode, useEffect } from 'react';
import { usePropelAuthContext } from 'src/auth/useAuthContext';

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV !== 'production',
});

type GrowthBookProviderProps = {
  children: ReactNode;
};

export default function GrowthBookUpsellProvider({ children }: GrowthBookProviderProps) {
  const { user, currentStoreId } = usePropelAuthContext();

  useEffect(() => {
    growthbook.loadFeatures();
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    const store = user.stores.find((storeItem: any) => storeItem.primary);

    if (!store) {
      throw new Error('Missing primary store');
    }
    growthbook.setAttributes({
      id: user?._id,
      email: user?.email?.email_address,
      company: currentStoreId ?? store.store._id,
    });
  }, [user, currentStoreId]);
  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>;
}
