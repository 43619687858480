import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  BranchReportType,
  DealsReportType,
  FulfillmentReportType,
  HourlySalesTrendType,
  PerDateSalesTrendType,
  ProductsReportType,
  SalesSummaryType,
  SummaryCustomerGrowthReportType,
} from 'src/@types/v2/reports';

// ----------------------------------------------------------------------

export type DashboardStateType = {
  error: boolean;
  salesTrend: {
    data: {
      hourly: {
        current: HourlySalesTrendType;
        previous: HourlySalesTrendType;
      };
      perDate: {
        current: PerDateSalesTrendType;
        previous: PerDateSalesTrendType;
      };
    };
    isLoading: boolean;
  };
  deals: {
    data: DealsReportType;
    isLoading: boolean;
  };
  branch: {
    data: BranchReportType;
    isLoading: boolean;
  };
  salesByProduct: {
    data: ProductsReportType;
    isLoading: boolean;
  };
  fulfillment: {
    data: FulfillmentReportType;
    isLoading: boolean;
  };
  salesSummary: {
    data: {
      current: SalesSummaryType;
      previous: SalesSummaryType;
    };
    isLoading: boolean;
  };
  bookingActivity: {
    data: HourlySalesTrendType;
    isLoading: boolean;
  };
  customerGrowth: {
    data: {
      current: SummaryCustomerGrowthReportType;
      previous: SummaryCustomerGrowthReportType;
    };
    isLoading: boolean;
  };
};

const initialState: DashboardStateType = {
  error: false,
  salesTrend: {
    data: {
      hourly: {
        current: {} as HourlySalesTrendType,
        previous: {} as HourlySalesTrendType,
      },
      perDate: {
        current: {} as PerDateSalesTrendType,
        previous: {} as PerDateSalesTrendType,
      },
    },
    isLoading: false,
  },
  deals: {
    data: {} as DealsReportType,
    isLoading: false,
  },
  branch: {
    data: {} as BranchReportType,
    isLoading: false,
  },
  salesByProduct: {
    data: {} as ProductsReportType,
    isLoading: false,
  },
  fulfillment: {
    data: {} as FulfillmentReportType,
    isLoading: false,
  },
  salesSummary: {
    data: {
      current: {} as SalesSummaryType,
      previous: {} as SalesSummaryType,
    },
    isLoading: false,
  },
  bookingActivity: {
    data: {} as HourlySalesTrendType,
    isLoading: false,
  },
  customerGrowth: {
    data: {
      current: {} as SummaryCustomerGrowthReportType,
      previous: {} as SummaryCustomerGrowthReportType,
    },
    isLoading: false,
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      switch (action.payload) {
        case 'sales-trend':
          state.salesTrend.isLoading = true;
          break;
        case 'branch':
          state.branch.isLoading = true;
          break;
        case 'sales-by-product':
          state.salesByProduct.isLoading = true;
          break;
        case 'fulfillment':
          state.fulfillment.isLoading = true;
          break;
        case 'sales-summary':
          state.salesSummary.isLoading = true;
          break;
        case 'activity':
          state.bookingActivity.isLoading = true;
          break;
        case 'growth':
          state.customerGrowth.isLoading = true;
          break;
        case 'deals':
          state.deals.isLoading = true;
          break;
        default:
          break;
      }
    },

    // HAS ERROR
    hasError(state, action) {
      state.salesByProduct.isLoading = false;
      state.fulfillment.isLoading = false;
      state.salesSummary.isLoading = false;
      state.deals.isLoading = false;
      state.bookingActivity.isLoading = false;
      state.customerGrowth.isLoading = false;
      state.salesTrend.isLoading = false;
      state.branch.isLoading = false;
      state.error = action.payload;
    },

    // Set Sales Trend
    setDashboardSalesTrend(
      state,
      action: {
        payload: {
          current: HourlySalesTrendType | PerDateSalesTrendType;
          previous: HourlySalesTrendType | PerDateSalesTrendType;
          isHourly: boolean;
        };
      }
    ) {
      state.salesTrend.isLoading = false;
      const { current, previous, isHourly } = action.payload;
      const { hourly, perDate } = state.salesTrend.data;
      if (isHourly) {
        hourly.current = current as HourlySalesTrendType;
        hourly.previous = previous as HourlySalesTrendType;
      } else {
        perDate.current = current as PerDateSalesTrendType;
        perDate.previous = previous as PerDateSalesTrendType;
      }
    },

    // set Deals
    setDashboardDeals(state, action) {
      state.deals.isLoading = false;
      state.deals.data = action.payload;
    },

    // Set Branch
    setDashboardBranch(state, action) {
      state.branch.isLoading = false;
      state.branch.data = action.payload;
    },

    // Set Sales By Product
    setDashboardSalesByProduct(state, action) {
      state.salesByProduct.isLoading = false;
      state.salesByProduct.data = action.payload;
    },

    // set booking activity
    setDashboardBookingActivity(state, action) {
      state.bookingActivity.isLoading = false;
      state.bookingActivity.data = action.payload;
    },

    // set customer growth
    setDashboardCustomerGrowth(
      state,
      action: {
        payload: {
          current: SummaryCustomerGrowthReportType;
          previous: SummaryCustomerGrowthReportType;
        };
      }
    ) {
      const { current, previous } = action.payload;
      state.customerGrowth.isLoading = false;
      state.customerGrowth.data.current = current;
      state.customerGrowth.data.previous = previous;
    },

    setDashboardFulfillment(state, action) {
      state.fulfillment.isLoading = false;
      state.fulfillment.data = action.payload;
    },

    setDashboardSalesSummary(
      state,
      action: {
        payload: {
          current: SalesSummaryType;
          previous: SalesSummaryType;
        };
      }
    ) {
      state.salesSummary.isLoading = false;
      const { current, previous } = action.payload;
      state.salesSummary.data.current = current;
      state.salesSummary.data.previous = previous;
    },
  },
});

export const useDashboard = (): DashboardStateType =>
  useSelector((RootState: { dashboard: DashboardStateType }) => RootState.dashboard);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  setDashboardBranch,
  setDashboardCustomerGrowth,
  setDashboardFulfillment,
  setDashboardSalesSummary,
  setDashboardSalesByProduct,
  setDashboardDeals,
  setDashboardSalesTrend,
  setDashboardBookingActivity,
} = slice.actions;
