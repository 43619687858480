import numeral from 'numeral';

// ----------------------------------------------------------------------

function format(number: number) {
  const formattedValue = numeral(number)
    .format(Number.isInteger(number) ? '0,0' : '0,0.00')
    .replace(/[,.]/g, (m) => (m === ',' ? '.' : ','));
  if (formattedValue === '0,00' || !formattedValue.endsWith(',50')) {
    return `${formattedValue.split(',')[0]}`;
  }

  return formattedValue;
}
export function fCurrency(number: number): string {
  const val = format(number);
  return `${val} kr`;
}

export function fCurrencyRange(start: number, end: number) {
  return `${format(start)} - ${format(end)} kr`;
}

export function fPercent(number: number, decimals = 1) {
  return numeral(number / 100).format(decimals ? '0,0.[00]%' : '0,0%');
}

export function fNumber(number: number) {
  const val = format(number);
  // Remove trailing zeros and the decimal point if it's followed by zeros
  return val.replace(/(\.00|\.0|\.|,00|,0|,)$/g, '');
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export function fHex(number: number) {
  if (!Number.isInteger(number) || number < 0) {
    throw new Error('Input must be a non-negative integer.');
  }
  const hex = number.toString(16);
  return hex.length % 2 === 0 ? hex : `0${hex}`;
}
