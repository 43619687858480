import { format } from 'date-fns';
import { fCurrency } from '@utils/formatter-number';
import type { ExtrasType, OrderDetailType, OrderType } from '../../@types/v2/orders/list';
import { PRIORITIZED_EXTRAS } from './commons';

export type LabelSettingsType = {
  LABEL_PRINT_SKU: boolean;
};

const splitProduct = (item: OrderDetailType, xOffset = 0) => {
  let leftPizzaExtrasOverflow = 0;

  return `
      ^FWR
      ^CF0,30
      ^FO780,30^FDSPLIT PIZZA^FS
      ^CF0,25
      ^FO750,30^FD${item.split_items[0].variation.name.toUpperCase()}^FS
      ${
        // @ts-expect-error: split item is not defined yet
        item.split_items.map((splitItem, index) => {
          const extrasData = displayExtras(
            splitItem.extras,
            685 - index * (60 + Math.floor(leftPizzaExtrasOverflow / 4) * 15),
            40
          );

          if (extrasData.length > 4 && index === 0) {
            leftPizzaExtrasOverflow = extrasData.length;
          }

          return `
            ^CF0,30
            ^FO${710 - index * (60 + Math.floor(leftPizzaExtrasOverflow / 4) * 15)},30^FD${
              index === 0 ? 'L' : 'R'
            } ${splitItem.name.toUpperCase()}^FS
            ${extrasData.data}
          `;
        })
      }
    `;
};

const variation = (item: OrderDetailType, xOffset = 0) => `
      ^FWR
      ^CF0,30
      ^FO780,30^FD${item.name.toUpperCase()}^FS
      ^CF0,25
      ^FO750,30^FD${item.variation.name.toUpperCase()}^FS
    `;

const variationExtras = (item: OrderDetailType, xOffset = 0) => `
      ^FWR
      ^CF0,30
      ^FO780,30^FD${item.name.toUpperCase()}^FS
      ^CF0,25
      ^FO750,30^FD${item.variation.name.toUpperCase()}^FS
      ${displayExtras(item.extras, 725, 40).data}
    `;

const displayExtras = (extras: ExtrasType[], xOffset = 0, yOffset = 0) => {
  // sort extras based on PRIORITIZED_EXTRAS
  // then if extras excluded_items.length > 0, put it on the start of the array
  const sortedExtras = extras
    ?.slice()
    .sort((a, b) => PRIORITIZED_EXTRAS.indexOf(a.name) - PRIORITIZED_EXTRAS.indexOf(b.name));

  const generatedExtras: string[] = [];

  sortedExtras.forEach((extra) => {
    if (extra.excluded_items.length > 0) {
      extra.excluded_items.forEach((item) => {
        if (item.include) {
          const words = item.name.split(' ');

          generatedExtras.push(
            `-${item.doubled ? 'x2' : ''}${words[0].slice(0, 3).toUpperCase()}${
              words[1] ? words[1].slice(0, 1).toUpperCase() : ''
            }`
          );
        }
      });
    }

    if (extra.items.length > 0) {
      extra.items.forEach((item) => {
        if (!item.include || item.doubled) {
          const words = item.name.split(' ');

          generatedExtras.push(
            `+${item.doubled ? 'x2' : ''}${words[0].slice(0, 3).toUpperCase()}${
              words[1] ? words[1].slice(0, 1).toUpperCase() : ''
            }`
          );
        }
      });
    }
  });

  // sort generated extas, if string contains -, put it on beginning of the array
  generatedExtras.sort((a, b) => (a.includes('-') ? 0 : 1) - (b.includes('-') ? 0 : 1));

  const renderExtras = () => {
    let temp = '';

    // render four extras at a time
    for (let i = 0; i < generatedExtras.length; i += 4) {
      temp += `
        ^FO${xOffset - (i / 4) * 25},${yOffset}^FD${generatedExtras.slice(i, i + 4).join('')}^FS
      `;
    }

    return temp;
  };

  return {
    data: `
    ^CF0,25
    ${generatedExtras.length > 0 ? renderExtras() : ''}    
  `,
    length: generatedExtras.length,
  };
};

const simpleItem = (item: OrderDetailType, settings: LabelSettingsType, xOffset = 0) => {
  let label = '^FWR';

  const isNameRequiresTwoLines = item.name.length > 20;

  if (isNameRequiresTwoLines) {
    label += `
      ^CF0,30
      ^FO780,30^FD${item.name.slice(0, 20).toUpperCase()}^FS
      ^CF0,30
      ^FO750,30^FD${item.name.slice(20, 40).toUpperCase()}^FS
    `;

    if (settings.LABEL_PRINT_SKU && item.sku) {
      label += `
        ^CF0,25
        ^FO720,30^FDSKU: ${item.sku}^FS
      `;
    }
  } else {
    label += `
      ^CF0,30
      ^FO780,30^FD${item.name.slice(0, 20).toUpperCase()}^FS
    `;

    if (settings.LABEL_PRINT_SKU && item.sku) {
      label += `
        ^CF0,25
        ^FO750,30^FDSKU: ${item.sku}^FS
      `;
    }
  }

  return label;
};

export const head = (item: OrderDetailType, settings: LabelSettingsType) => {
  // top padding
  const xOffset = 40;

  switch (item.type) {
    case 'split_product':
      return splitProduct(item, xOffset);
    case 'variation_extras':
      return variationExtras(item, xOffset);
    case 'variation':
      return variation(item, xOffset);
    default:
      return simpleItem(item, settings, xOffset);
  }
};

const generateShippingDetails = (
  order: OrderType
): {
  offset: number;
  data: string;
} => {
  if (order.order_method === 'delivery') {
    return {
      offset: order.shipping.address.length > 17 ? 95 : 35,
      data: `
    ^CF0,30
    ^FO70,240,0^FDA: ${order.shipping.address.slice(0, 17)}^FS 
    ${
      order.shipping.address.length > 17
        ? `
      ^FO70,270,0^FD${order.shipping.address.slice(17, 34)}^FS 
      ^FO70,300,0^FDZIP ${order.shipping.postcode}^FS 
      `
        : `
      ^FO70,270,0^FDZIP ${order.shipping.postcode}^FS 
      `
    }
    `,
    };
  }
  return {
    offset: 0,
    data: '',
  };
};

const displayNotes = (order: OrderType, offset = 0) => {
  if (order.order_notes.length > 0) {
    return `
    ^CF0,22
    ^FO70,${235 + offset},0^FDNOTE: ${order.order_notes[0].note.slice(0, 19)}^FS
    ^FO70,${255 + offset},0^FD${order.order_notes[0].note.slice(19, 31)}^FS
    `;
  }
  return '';
};

const orderMethod = (order: OrderType) => {
  // handle wolt drive orders
  if (order.data.wolt_drive_order_id) {
    return 'WOLT DRIVE';
  }

  return order.order_method.toUpperCase();
};

export const tail = (order: OrderType, index: number, length: number) => {
  // const estimationWaitingTime =
  //   order.order_method === 'delivery'
  //     ? order.branch.settings.delivery.waiting_time || 0
  //     : order.branch.settings.pickup.waiting_time || 0;

  // const estimatedTime = addMinutesToDate(
  //   new Date(order.createdAt),
  //   order.waiting_time ?? estimationWaitingTime
  // );

  // const waitingTimeInterval = order.waiting_time >= 60 ? 10 : 5;

  const { offset: shippingOffset, data: shippingDetails } = generateShippingDetails(order);

  let totalDue = order.total;

  if (order.giftcard) {
    totalDue -= order.giftcard.value_used;
  }

  return `
    ^FWR
    ^FO60,0^GB3,500,3^FS
    ^CF0,50
    ^FO0,80,0^FD${orderMethod(order)}^FS
    ^FO330,0^GB3,500,3^FS
    ^FWN
    ^CF0,30
    ^FO340,30,0^FD${order.date_paid ? 'PAID' : 'UNPAID'}^FS
    ^CF0,200
    ^FO340,80,0^FD${order.date_paid ? '' : 'X'}^FS
    ^CF0,30
    ^FO340,300,0^FD${fCurrency(totalDue).toUpperCase()}^FS
    ^FO460,0^GB3,500,3^FS
    ^CF0,50
    ^FO120,10,0^FD${index + 1} OF ${length}^FS
    ^FO60,60^GB270,3,3^FS
    ^CF0,27
    ^FO70,70,0^FD${format(new Date(order.createdAt), 'dd-MM-yy')} AT ${format(
      new Date(order.createdAt),
      'HH:mm'
    )}^FS
    ^FO60,95^GB270,3,3^FS
    ^CF0,50
    ^FO70,105,0^FD#${order.order_number}^FS
    ^FO60,150^GB270,3,3^FS
    ^CF0,40
    ${
      order.customer &&
      order.customer.name &&
      `^FO70,165,0^FDN: ${order.customer.name.slice(0, 10).toUpperCase()}^FS`
    }
    ^CF0,30
    ${
      order.customer &&
      order.customer.phone.phone_number &&
      `
      ^FO70,205,0^FDS: ${order.customer.phone.phone_number.slice(
        0,
        4
      )} ${order.customer.phone.phone_number.slice(4)}^FS
      `
    }
    ${shippingDetails}
    ${displayNotes(order, shippingOffset)}
  `;
};
