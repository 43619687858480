export const generateVariations = <T>(arrays: T[][]): T[][] => {
  const generateCombinations = (curArrays: T[][], combination: T[] = []): T[][] => {
    if (curArrays.length === 0) return [combination];

    const combinations: T[][] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const element of curArrays[0]) {
      const subCombinations = generateCombinations(curArrays.slice(1), combination.concat(element));
      combinations.push(...subCombinations);
    }

    return combinations;
  };

  return generateCombinations(arrays);
};

export default generateVariations;
