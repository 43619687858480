import { ActionMapType, AuthRoleType, AuthStateType, AuthUserType } from '../types/auth';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
    role: AuthRoleType;
    accessToken: string | null;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    role: AuthRoleType;
    accessToken: string;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
    role: AuthRoleType;
    accessToken: string | null;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  role: null,
  api_version: 0,
  accessToken: null,
};

const reducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        api_version: action.payload.user?.api_version || 2,
        user: action.payload.user,
        role: action.payload.role,
        accessToken: action.payload.accessToken,
      };
    case Types.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        api_version: action.payload.user?.api_version || 2,
        user: action.payload.user,
        role: action.payload.role,
        accessToken: action.payload.accessToken,
      };
    case Types.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        role: null,
        accessToken: null,
      };
    case Types.REGISTER:
      return {
        ...state,
        isAuthenticated: true,
        api_version: action.payload.user?.api_version || 2,
        user: action.payload.user,
        role: action.payload.role,
        accessToken: action.payload.accessToken,
      };
    default:
      return state;
  }
};

export { initialState, reducer, Types };
