import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  BranchReportType,
  CouponReportType,
  DiscountReportType,
  ExtrasReportType,
  FulfillmentReportType,
  HourlySalesTrendType,
  PerDateBranchReportType,
  PerDateFulfillmentReportType,
  PerDateSalesByCategoryType,
  PerDateSalesTrendType,
  ProductsReportType,
  PaymentMethodsType,
  PerDatePaymentMethodsType,
  SalesByCategoryType,
  SummaryCustomerReportType,
  PerDateCustomerGrowthType,
  SourceReportType,
  PerDateSourceReportType,
  PerDateCouponReportType,
  PerDateDiscountReportType,
  SalesSummaryType,
  DealsReportType,
  PerDateDealsReportType,
  PerDateWoltDriveType,
  WaitingTimeSummaryType,
  WaitingTimeInsightType,
} from 'src/@types/v2/reports';

// ----------------------------------------------------------------------

type ReportsStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string;
  paymentMethodsData: {
    summary: PaymentMethodsType;
    perDate: PerDatePaymentMethodsType;
  };
  salesByCategoryData: {
    summary: SalesByCategoryType;
    perDate: PerDateSalesByCategoryType;
  };
  salesByDealData: {
    summary: DealsReportType;
    perDate: PerDateDealsReportType;
  };
  salesByCustomerData: {
    summary: SummaryCustomerReportType;
  };
  fulfillmentData: {
    summary: FulfillmentReportType;
    perDate: PerDateFulfillmentReportType;
  };
  productsData: ProductsReportType;
  couponData: {
    summary: CouponReportType;
    perDate: PerDateCouponReportType;
  };
  discountData: {
    summary: DiscountReportType;
    perDate: PerDateDiscountReportType;
  };
  branchData: {
    summary: BranchReportType;
    perDate: PerDateBranchReportType;
  };
  extrasData: ExtrasReportType;
  salesTrend: {
    hourly: {
      current: HourlySalesTrendType;
      previous: HourlySalesTrendType;
    };
    perDate: {
      current: PerDateSalesTrendType;
      previous: PerDateSalesTrendType;
    };
  };
  customerGrowth: {
    perDate: PerDateCustomerGrowthType;
  };
  woltDrive: {
    perDate: PerDateWoltDriveType;
  };
  waitingTime: {
    loading: {
      summary: boolean;
      insight: boolean;
    };
    summary: WaitingTimeSummaryType;
    prevSummary: WaitingTimeSummaryType;
    insight: WaitingTimeInsightType;
  };
  salesBySourceData: {
    summary: SourceReportType;
    perDate: PerDateSourceReportType;
  };
  salesSummaryData: {
    current: SalesSummaryType;
    previous: SalesSummaryType;
  };
};

const initialState: ReportsStateType = {
  isLoading: false,
  error: false,
  sortBy: 'order_total-desc',
  salesSummaryData: {
    current: {} as SalesSummaryType,
    previous: {} as SalesSummaryType,
  },
  salesBySourceData: {
    summary: {} as SourceReportType,
    perDate: {} as PerDateSourceReportType,
  },
  paymentMethodsData: {
    summary: {} as PaymentMethodsType,
    perDate: {} as PerDatePaymentMethodsType,
  },
  fulfillmentData: {
    summary: {} as FulfillmentReportType,
    perDate: {} as PerDateFulfillmentReportType,
  },
  salesByCategoryData: {
    summary: {} as SalesByCategoryType,
    perDate: {} as PerDateSalesByCategoryType,
  },
  salesByDealData: {
    summary: {} as DealsReportType,
    perDate: {} as PerDateDealsReportType,
  },
  salesByCustomerData: {
    summary: {} as SummaryCustomerReportType,
  },
  productsData: {} as ProductsReportType,
  couponData: {
    summary: {} as CouponReportType,
    perDate: {} as PerDateCouponReportType,
  },
  discountData: {
    summary: {} as DiscountReportType,
    perDate: {} as PerDateDiscountReportType,
  },
  branchData: {
    summary: {} as BranchReportType,
    perDate: {} as PerDateBranchReportType,
  },
  salesTrend: {
    hourly: {
      current: {} as HourlySalesTrendType,
      previous: {} as HourlySalesTrendType,
    },
    perDate: {
      current: {} as PerDateSalesTrendType,
      previous: {} as PerDateSalesTrendType,
    },
  },
  extrasData: {} as ExtrasReportType,
  customerGrowth: {
    perDate: {} as PerDateCustomerGrowthType,
  },
  woltDrive: {
    perDate: {} as PerDateWoltDriveType,
  },
  waitingTime: {
    loading: {
      summary: true,
      insight: true,
    },
    summary: {} as WaitingTimeSummaryType,
    prevSummary: {} as WaitingTimeSummaryType,
    insight: {} as WaitingTimeInsightType,
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    setSortByReport(state, action) {
      state.sortBy = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET CUSTOMER GROWTH
    setCustomerGrowth(state, action) {
      state.isLoading = false;
      state.customerGrowth.perDate = action.payload;
    },

    // SET WOLT DRIVE
    setWoltDrive(state, action) {
      state.isLoading = false;
      state.woltDrive.perDate = action.payload;
    },
    // GET reports
    setPaymentMethods(
      state,
      action: {
        payload: { data: PaymentMethodsType | PerDatePaymentMethodsType; isSummary: boolean };
      }
    ) {
      const { data, isSummary } = action.payload;
      state.isLoading = false;
      if (isSummary) state.paymentMethodsData.summary = data as PaymentMethodsType;
      else state.paymentMethodsData.perDate = data as PerDatePaymentMethodsType;
    },
    setSalesByCustomer(state, action) {
      state.isLoading = false;
      state.salesByCustomerData.summary = action.payload;
    },
    setSalesByCategory(
      state,
      action: {
        payload: { data: SalesByCategoryType | PerDateSalesByCategoryType; isSummary: boolean };
      }
    ) {
      const { data, isSummary } = action.payload;
      state.isLoading = false;
      if (isSummary) state.salesByCategoryData.summary = data as SalesByCategoryType;
      else state.salesByCategoryData.perDate = data as PerDateSalesByCategoryType;
    },
    setFulfillment(
      state,
      action: {
        payload: { data: FulfillmentReportType | PerDateFulfillmentReportType; isSummary: boolean };
      }
    ) {
      const { data, isSummary } = action.payload;
      state.isLoading = false;
      if (isSummary) state.fulfillmentData.summary = data as FulfillmentReportType;
      else state.fulfillmentData.perDate = data as PerDateFulfillmentReportType;
    },
    setSalesTrend(
      state,
      action: {
        payload: {
          current: HourlySalesTrendType | PerDateSalesTrendType;
          previous: HourlySalesTrendType | PerDateSalesTrendType;
          isHourly: boolean;
        };
      }
    ) {
      const { current, previous, isHourly } = action.payload;
      state.isLoading = false;
      if (isHourly) {
        state.salesTrend.hourly.current = current as HourlySalesTrendType;
        state.salesTrend.hourly.previous = previous as HourlySalesTrendType;
      } else {
        state.salesTrend.perDate.current = current as PerDateSalesTrendType;
        state.salesTrend.perDate.previous = previous as PerDateSalesTrendType;
      }
    },

    setCouponReport(
      state,
      action: {
        payload: {
          data: CouponReportType | PerDateCouponReportType;
          isSummary: boolean;
        };
      }
    ) {
      const { data, isSummary } = action.payload;
      state.isLoading = false;
      if (isSummary) state.couponData.summary = data as CouponReportType;
      else state.couponData.perDate = data as PerDateCouponReportType;
    },
    setDiscountReport(
      state,
      action: {
        payload: {
          data: DiscountReportType | PerDateDiscountReportType;
          isSummary: boolean;
        };
      }
    ) {
      const { data, isSummary } = action.payload;
      state.isLoading = false;
      if (isSummary) state.discountData.summary = data as DiscountReportType;
      else state.discountData.perDate = data as PerDateDiscountReportType;
    },
    setBranchReport(
      state,
      action: {
        payload: { data: BranchReportType | PerDateBranchReportType; isSummary: boolean };
      }
    ) {
      const { data, isSummary } = action.payload;
      state.isLoading = false;
      if (isSummary) state.branchData.summary = data as BranchReportType;
      else state.branchData.perDate = data as PerDateBranchReportType;
    },

    setSalesByDeal(
      state,
      action: {
        payload: { data: DealsReportType | PerDateDealsReportType; isSummary: boolean };
      }
    ) {
      const { data, isSummary } = action.payload;
      state.isLoading = false;
      if (isSummary) state.salesByDealData.summary = data as DealsReportType;
      else state.salesByDealData.perDate = data as PerDateDealsReportType;
    },

    setSalesSummaryReport(
      state,
      action: {
        payload: { current: SalesSummaryType; previous: SalesSummaryType };
      }
    ) {
      const { current, previous } = action.payload;
      state.isLoading = false;
      state.salesSummaryData.current = current;
      state.salesSummaryData.previous = previous;
    },

    setProductsReport(state, action) {
      state.isLoading = false;
      state.productsData = action.payload;
    },
    startWaitingTimeLoading(
      state,
      action: {
        payload: 'insight' | 'summary';
      }
    ) {
      if (action.payload === 'insight') {
        state.waitingTime.loading.insight = true;
      } else if (action.payload === 'summary') {
        state.waitingTime.loading.summary = true;
      }
    },
    setWaitingTimeSummary(state, action) {
      state.waitingTime.loading.summary = false;
      state.waitingTime.prevSummary = action.payload.prevSummary;
      state.waitingTime.summary = action.payload.summary;
    },
    setWaitingTimeInsight(state, action) {
      state.waitingTime.loading.insight = false;
      state.waitingTime.insight = action.payload;
    },
    setExtrasReport(state, action) {
      state.isLoading = false;
      state.extrasData = action.payload;
    },
    setSalesBySource(
      state,
      action: {
        payload: { data: SourceReportType | PerDateSourceReportType; isSummary: boolean };
      }
    ) {
      const { data, isSummary } = action.payload;
      state.isLoading = false;
      if (isSummary) state.salesBySourceData.summary = data as SourceReportType;
      else state.salesBySourceData.perDate = data as PerDateSourceReportType;
    },
  },
});

export const useReports = (): ReportsStateType =>
  useSelector((RootState: { reports: ReportsStateType }) => RootState.reports);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  setSortByReport,
  setPaymentMethods,
  setSalesByCategory,
  setSalesByDeal,
  setCouponReport,
  setFulfillment,
  setDiscountReport,
  setBranchReport,
  setExtrasReport,
  setProductsReport,
  setSalesTrend,
  setSalesByCustomer,
  setCustomerGrowth,
  setSalesBySource,
  setSalesSummaryReport,
  setWoltDrive,
  setWaitingTimeSummary,
  setWaitingTimeInsight,
  startWaitingTimeLoading,
} = slice.actions;
