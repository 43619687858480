import { createContext, ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react';
import axios from 'axios';
import {
  RequiredAuthProvider as PropelAuthProvider,
  useLogoutFunction,
  useRedirectFunctions,
  useAuthInfo,
} from '@propelauth/react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { LoadingScreen } from '@components/loading';
import { AdminType, PropelAdminType } from 'src/@types/v2/admins';
import localStorageAvailable from '@utils/template/localStorageAvailable';
import { PropelAuthContextType } from '../types/auth';
import { getActiveOrg, redirectToKDS, setSessionAsync } from '../utils';
import { AdminRolesType } from '../types/roles';
import { initialState, reducer, Types } from './reducer';

// We need to create this client here because we don't
// have access to the AxiosContext yet since it requires
// AuthProvider itself
const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NEW_API_ENDPOINT,
});

// ----------------------------------------------------------------------

export const AuthContext = createContext<PropelAuthContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

type PropelProviderProps = {
  children: ReactNode;
  activeOrgName: string | null;
  storageAvailable: boolean;
};

function PropelProvider({ children, activeOrgName, storageAvailable }: PropelProviderProps) {
  const authInfo = useAuthInfo();
  const { replace, reload, pathname } = useRouter();

  const logoutFn = useLogoutFunction();
  const { redirectToLoginPage, redirectToSignupPage } = useRedirectFunctions();
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = useCallback(async () => {
    redirectToLoginPage();
  }, [redirectToLoginPage]);

  const register = useCallback(async () => {
    redirectToSignupPage();
  }, [redirectToSignupPage]);

  const setStoreId = useCallback(
    async (storeId: string) => {
      const propelData = state.propelUser;
      const { user } = state;

      if (!propelData || !user) {
        return;
      }

      const orgMemberInfo = propelData.org_member_info;
      const org = await getActiveOrg(orgMemberInfo, storeId);

      if (!org) {
        Sentry.captureException(new Error('No primary store found'));
        alert(`Can't find store`);
        return;
      }

      const { userAssignedRole, userPermissions, orgId, orgName } = org;

      const { stores } = user;
      const branchIds = (stores.find((s) => s.store._id === storeId) || {}).branches || [];

      const updatedRole = userAssignedRole;
      const updatedPermissions = userPermissions;
      const activeOrgId = orgId;

      const sessionData = {
        accessToken: storageAvailable ? localStorage.getItem('accessToken') : null,
        activeOrgName: orgName,
        activeStore: storeId,
        globalBranchIds: branchIds,
      };

      await setSessionAsync(sessionData);

      dispatch({
        type: Types.CHANGE_STORE,
        payload: {
          currentStoreId: storeId,
          branchIds,
          globalBranchIds: branchIds,
          role: updatedRole,
          permissions: updatedPermissions,
          propelActiveOrgId: activeOrgId,
        },
      });
      const kdsUrl = redirectToKDS(pathname, updatedPermissions);
      const redirectUrl = kdsUrl || pathname;
      replace(redirectUrl);
      reload();
    },
    [pathname, replace, reload, state, storageAvailable]
  );

  const setGlobalBranchIds = useCallback(async (branchIds: string[]) => {
    const sessionData = {
      accessToken: localStorage.getItem('accessToken'),
      activeOrgName: localStorage.getItem('activeOrgName'),
      activeStore: localStorage.getItem('activeStore'),
      globalBranchIds: branchIds,
    };
    await setSessionAsync(sessionData);
    dispatch({
      type: Types.CHANGE_GLOBAL_BRANCH,
      payload: {
        globalBranchIds: branchIds,
      },
    });
  }, []);

  const setAdminRole = useCallback(async (role: AdminRolesType) => {
    dispatch({
      type: Types.CHANGE_ROLE,
      payload: {
        role,
      },
    });
  }, []);

  const logout = useCallback(async () => {
    await logoutFn(true);
    await setSessionAsync(null);
    dispatch({ type: Types.LOGOUT });
  }, [logoutFn]);

  const initialize = useCallback(async (accessToken: string) => {
    try {
      if (accessToken) {
        const { data } = await axiosInstance.get('/v2/auth/propel/admin', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const curStoreId = localStorage.getItem('activeStore');
        const mongoData: AdminType = data.data;
        const propelData: PropelAdminType = data.propel_data;

        const primaryStore = mongoData.stores.find((s) => s.primary);
        const selectedStoreId = curStoreId || primaryStore?.store._id;

        if (!selectedStoreId) {
          Sentry.captureException(new Error('No primary store found. Please reload the page.'));
          alert('No primary store found. Please reload the page.');
          return;
        }

        const globalBranchIds = JSON.parse(localStorage.getItem('globalBranchIds') || '[]');
        const branchIds =
          mongoData.stores.find((s) => s.store._id === selectedStoreId)?.branches || [];
        const orgMemberInfo = propelData.org_member_info;
        const activeOrg = await getActiveOrg(orgMemberInfo, selectedStoreId);

        if (!activeOrg) {
          Sentry.captureException(new Error('No active org found. Please reload the page.'));
          alert('No active org found. Please reload the page.');
          return;
        }

        const { userAssignedRole, orgName, userPermissions, orgId } = activeOrg;

        const sessionData = {
          accessToken,
          activeOrgName: orgName,
          activeStore: selectedStoreId,
          globalBranchIds,
        };

        await setSessionAsync(sessionData);

        const user = data.data;
        const role = userAssignedRole;

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
            branchIds,
            globalBranchIds: globalBranchIds || branchIds,
            propelUser: propelData,
            role,
            currentStoreId: selectedStoreId,
            permissions: userPermissions,
            propelActiveOrgId: orgId,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
            propelUser: null,
            role: null,
            branchIds: [],
            globalBranchIds: [],
            permissions: [],
            currentStoreId: null,
            propelActiveOrgId: '',
          },
        });
      }
    } catch (err) {
      Sentry.captureException(err);
      alert('Something went wrong! Please reload the page.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getToken = async (accessToken: string) => {
      const sessionData = {
        accessToken,
        activeOrgName,
        activeStore: localStorage.getItem('activeStore'),
        globalBranchIds: JSON.parse(localStorage.getItem('globalBranchIds') || '[]'),
      };
      await setSessionAsync(sessionData);
      if (!state.isAuthenticated) {
        await initialize(accessToken);
      }
    };

    if (authInfo.accessToken) {
      getToken(authInfo.accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo.accessToken]);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      permissions: state.permissions,
      propelUser: state.propelUser,
      user: state.user,
      branchIds: state.branchIds,
      globalBranchIds: state.globalBranchIds,
      propelActiveOrgId: state.propelActiveOrgId,
      currentStoreId: state.currentStoreId,
      role: state.role,
      method: 'propel',
      login,
      register,
      logout,
      setStoreId,
      setGlobalBranchIds,
      setAdminRole,
    }),
    [
      state.isInitialized,
      state.isAuthenticated,
      state.permissions,
      state.propelUser,
      state.user,
      state.branchIds,
      state.globalBranchIds,
      state.propelActiveOrgId,
      state.currentStoreId,
      login,
      logout,
      register,
      setGlobalBranchIds,
      setStoreId,
      setAdminRole,
      state.role,
    ]
  );
  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const storageAvailable = localStorageAvailable();
  const activeOrgName = storageAvailable ? localStorage.getItem('activeOrgName') : null;

  return (
    <PropelAuthProvider
      authUrl={process.env.NEXT_PUBLIC_AUTH_URL || ''}
      displayWhileLoading={<LoadingScreen />}
      getActiveOrgFn={() => activeOrgName}
    >
      <PropelProvider activeOrgName={activeOrgName} storageAvailable={storageAvailable}>
        {children}
      </PropelProvider>
    </PropelAuthProvider>
  );
}
