const getValueRange = (
  valueArray: number[],
  textAdornment?: string,
  textAdornmentPosition?: 'end' | 'start'
) => {
  const maxValue = Math.max(...valueArray);
  const minValue = Math.min(...valueArray);

  const getTextAdornment = () => {
    if (textAdornment && textAdornmentPosition) {
      switch (textAdornmentPosition) {
        case 'end':
          return maxValue === minValue
            ? `${maxValue} ${textAdornment}`
            : `${minValue} ${textAdornment}-${maxValue} ${textAdornment}`;
        case 'start':
          return maxValue === minValue
            ? `${textAdornment}${maxValue}`
            : `${textAdornment}${minValue}- ${textAdornment}${maxValue}`;
        default:
          return maxValue === minValue ? maxValue : `${minValue}-${maxValue}`;
      }
    } else {
      return maxValue === minValue ? maxValue : `${minValue}-${maxValue}`;
    }
  };

  return {
    max: maxValue,
    min: minValue,
    text: getTextAdornment(),
  };
};

export default getValueRange;
