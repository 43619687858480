import { useContext } from 'react';
import { AuthContext as JwtContext } from './jwt/JwtContext';
import { AuthContext as PropelContext } from './propel/PropelAuthContext';

//  If we want to change the authentication method,
//  we need to change as follow:
// - all the imports that using useJwtAuthContext / usePropelAuthContext
// - AuthProvider in src\pages\_app.tsx
// - anything related login and register form

const useJwtAuthContext = () => {
  const context = useContext(JwtContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');
  return context;
};

const usePropelAuthContext = () => {
  const context = useContext(PropelContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export { usePropelAuthContext, useJwtAuthContext };
