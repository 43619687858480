import { forwardRef, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import * as Sentry from '@sentry/nextjs';
import { useSnackbar } from '@components/snackbar';
import { waitingTimeLegacyFallback } from '@utils/legacy-fallback';
import type { OrderType, OrderDetailType } from '../../@types/v2/orders/list';
import { Body, DeliveryAddress, Header, Note, UnpaidBar, TestBar, MethodBar } from './commons';
import { head, tail, LabelSettingsType } from './label';

type Props = {
  order: OrderType;
};

type PropsWithCustomElements = Props & {
  ssn?: string;
};

export const PrintableOrder = forwardRef<HTMLDivElement, Props>(({ order }, ref) => {
  // regex check if order.customer.name contains "Testing123" in any case
  const isTestUser = /Testing123/gi.test(order?.customer?.name);

  return (
    <div
      ref={ref}
      style={{
        // width: '80mm',
        fontFamily: 'tabela Soft',
        textTransform: 'uppercase',
      }}
      id="order"
    >
      {order && (
        <>
          {isTestUser && <TestBar />}
          <Header
            customer={order.customer}
            store={order.store}
            method={order.order_method}
            number={order.order_number}
            branch={order.branch}
            paid={!!order.date_paid}
            createdAt={order.createdAt}
            mode="order"
            waitingTime={order.waiting_time || waitingTimeLegacyFallback(order)}
          />
          <MethodBar method={order.order_method} />
          {order.order_method === 'delivery' && order.shipping && (
            <DeliveryAddress shipping={order.shipping} />
          )}
          {!order.date_paid && <UnpaidBar />}
          {order.order_notes.length > 0 && <Note notes={order.order_notes} />}
          <Body
            additionalCosts={order.additional_cost}
            currency={order.currency}
            items={order.items}
            total={order.total}
            sub_total={order.sub_total}
            discount_total={order.discount_total}
            tax_total={order.tax_total}
            paid={false}
            mode="order"
            debug_orderNumber={order.order_number}
          />
        </>
      )}
    </div>
  );
});

export const PrintableReceipt = forwardRef<HTMLDivElement, PropsWithCustomElements>(
  ({ order, ssn }, ref) => {
    // regex check if order.customer.name contains "Testing123" in any case
    const isTestUser = /Testing123/gi.test(order?.customer?.name);

    return (
      <div
        ref={ref}
        style={{
          // width: '80mm',
          fontFamily: 'tabela Soft',
          textTransform: 'uppercase',
        }}
      >
        {isTestUser && <TestBar />}
        <Header
          customer={order.customer}
          store={order.store}
          method={order.order_method}
          number={order.order_number}
          branch={order.branch}
          createdAt={order.createdAt}
          paid={false}
          mode="receipt"
          waitingTime={order.waiting_time || waitingTimeLegacyFallback(order)}
          ssn={ssn}
        />
        {!order.date_paid && <UnpaidBar />}
        <Body
          additionalCosts={order.additional_cost}
          currency={order.currency}
          items={order.items}
          total={order.total}
          sub_total={order.sub_total}
          discount_total={order.discount_total}
          tax_total={order.tax_total}
          paid={false}
          mode="receipt"
        />
      </div>
    );
  }
);

PrintableOrder.displayName = 'PrintableOrder';
PrintableReceipt.displayName = 'PrintableReceipt';

export const OrderPrintOnDraw = ({ order }: Props) => {
  const printRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      // console.log('[PRINT] ORDER-AFTER', order._id);
    },
    onBeforePrint: () => {
      // console.log('[PRINT] ORDER-BEFORE', order._id);
    },
    onPrintError: (errorLocation, error) => {
      enqueueSnackbar(error.message || 'Error', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      console.error('[PRINT] ORDER-ERROR', errorLocation, error);
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    handlePrint();
  }, [order]);

  return (
    <div
      style={{
        overflow: 'hidden',
        height: '0',
        width: '0',
      }}
    >
      <PrintableOrder order={order} ref={printRef} />
    </div>
  );
};

export const ReceiptPrintOnDraw = ({ order }: Props) => {
  const printRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      // console.log('[PRINT] ORDER-AFTER', order._id);
    },
    onBeforePrint: () => {
      // console.log('[PRINT] ORDER-BEFORE', order._id);
    },
    onPrintError: (errorLocation, error) => {
      enqueueSnackbar(error.message || 'Error', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      console.error('[PRINT] RECEIPT-ERROR', errorLocation, error);
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    handlePrint();
  }, [order]);

  return (
    <div
      style={{
        overflow: 'hidden',
        height: '0',
        width: '0',
      }}
    >
      <PrintableReceipt order={order} ref={printRef} />
    </div>
  );
};

// generates label for a single item
export const generateLabel = (
  order: OrderType,
  item: OrderDetailType,
  settings: LabelSettingsType,
  index: number,
  length: number
) => {
  // init string as buffer
  let buffer = '^XA^CI28';

  buffer += head(item, settings);
  buffer += tail(order, index, length);

  // close buffer
  buffer += '^FWN,0^XZ';

  return buffer;
};
