import { createSlice, createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { useSelector } from '@redux/store';
import { calcPricing } from '@utils/orders/calculatePrice';
import { CartItemOrderSimple, DealsItem, OutputSide } from 'src/validations/orders/create-order';
import { ProductType, VariationType } from 'src/@types/v2/products';

// ----------------------------------------------------------------------

type MenuType = {
  _id: string;
  category: string;
  available_variations: Pick<VariationType, 'variants' | '_id'>[];
  free_extras: number;
  quantity: number;
  items: ProductType[];
};

type DealOrderDialogStateType = {
  quantity: number;
  output: OutputSide & {
    deals: DealsItem[];
  };
  onHold: boolean;
  populatedMenu: Record<string, MenuType[]>;
  cartRef: string;
  loading: boolean;
  error?: string;
  lastFetch?: string;
};

// STATES
const initialState = <DealOrderDialogStateType>{
  quantity: 1,
  output: {
    _id: '',
    variables: [],
    extras: [],
    deals: [],
    _data: null,
  },
  onHold: false,
  populatedMenu: {},
  cartRef: '',
  loading: false,
  error: undefined,
  lastFetch: undefined,
};

// ----------------------------------------------------------------------

// REDUCERS
const dealOrderSlice = createSlice({
  name: 'deal-order-dialog',
  initialState,
  reducers: {
    applyDeals: (state, action) => {
      const data = action.payload as any;
      if (!data || !data._id) return;

      state.output._id = data._id;
      state.output._data = data;
    },
    addItem: (state, action) => {
      const newItem = action.payload as any;
      if (!newItem || !newItem.id) return;

      state.output.deals = [
        ...state.output.deals.filter((item: any) => item.menu_id !== newItem.menu_id),
        newItem,
      ];
    },
    removeItem: (state, action) => {
      const menu_id = action.payload as string;
      if (!menu_id) return;

      state.output.deals = state.output.deals.filter((item: any) => item.menu_id !== menu_id);
    },
    updateExtrasData: (state, action) => {
      const { extrasData, menuId, split_mode } = action.payload as {
        extrasData: {
          left: OutputSide;
          right: OutputSide;
        };
        split_mode: boolean;
        menuId: string;
      };

      state.output.deals = state.output.deals.map((item: any) => {
        if (item.menu_id === menuId) {
          return {
            ...item,
            name: split_mode ? 'Split Pizza' : item.name,
            extra_data: extrasData,
            split_mode,
          };
        }
        return item;
      });
    },
    setQuantity: (state, action) => {
      const quantity = action.payload as number;
      state.quantity = quantity;
    },
    applyEditing: (state, action) => {
      const data = action.payload as CartItemOrderSimple;
      if (!data || !data.output._data || !data.output.deals) return;

      state.output = {
        _id: data.output._id,
        variables: [],
        extras: [],
        deals: data.output.deals,
        _data: data.output._data,
      };
      state.quantity = data.quantity;
    },
    setOnHold: (state, action) => {
      const value = action.payload as boolean;
      state.onHold = value;
    },
    reset: (state) => {
      if (!state.onHold) {
        return initialState;
      }
      return state;
    },
    resetMenu: (state) => {
      state.populatedMenu = initialState.populatedMenu;
    },
    applyPopulatedMenu: (state, action) => {
      state.populatedMenu = action.payload;
    },
    setInitialValue: (state, action) => {
      const { data, ref, quantity = 1 } = action.payload;

      if (ref) state.cartRef = ref;

      state.output = data.output;

      state.quantity = quantity;
    },
    resetPizzaId: (state, action) => {
      const id = action.payload as string;

      state.output.deals = state.output.deals.filter((item: any) => item.menu_id !== id);
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DealOrderDialogStateType>) => {
    builder.addCase(getDealMenuItem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDealMenuItem.fulfilled, (state, action) => {
      state.populatedMenu[action.payload.menu_id] = action.payload.response.data.data;
      state.loading = false;
    });
    builder.addCase(getDealMenuItem.rejected, (state) => {
      state.loading = false;
      state.error = 'Error while fetching menu items';
    });
    builder.addCase(getDealMenuItemAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDealMenuItemAll.fulfilled, (state, action) => {
      const { response, lastFetch } = action.payload;

      response.forEach((item: any) => {
        state.populatedMenu[item.menu_id] = item.data;
      });

      state.lastFetch = lastFetch;

      state.loading = false;
    });
    builder.addCase(getDealMenuItemAll.rejected, (state) => {
      state.loading = false;
      state.error = 'Error while fetching menu items';
    });
  },
});

// HOOKS
export const useDealDialog = () => useSelector((store) => store.dealOrderDialog);

/* HOOK: to get the price of the current product held in this redux state  */
export const usePricing = (): number => {
  const state = useSelector(
    (Root: { dealOrderDialog: DealOrderDialogStateType }) => Root.dealOrderDialog
  );

  if (!state.output._data) return 0;

  return calcPricing({
    output: state.output,
    quantity: state.quantity,
    split_mode: false,
  });
};

// Reducer
export default dealOrderSlice.reducer;

// Actions
export const {
  addItem,
  applyDeals,
  applyEditing,
  removeItem,
  reset,
  setOnHold,
  setQuantity,
  updateExtrasData,
  resetMenu,
  applyPopulatedMenu,
  setInitialValue,
  resetPizzaId,
} = dealOrderSlice.actions;

// Async Actions / Extra Reducers
export const getDealMenuItem = createAsyncThunk(
  'deals/getMenuItems',
  async (
    payload: { deals_id: string; menu_id: string; axiosInstance: AxiosInstance },
    thunkAPI
  ) => {
    const { deals_id, menu_id, axiosInstance } = payload;
    const response = await axiosInstance.get(`/v2/deals/${deals_id}/menus/${menu_id}/menu_items`);
    return { response, menu_id };
  }
);

export const getDealMenuItemAll = createAsyncThunk(
  'deals/getDealMenuItemAll',
  async (
    payload: { deals_id: string; menu_ids: string[]; axiosInstance: AxiosInstance },
    thunkAPI
  ) => {
    const { deals_id, menu_ids, axiosInstance } = payload;

    const promises = menu_ids.map((menu_id) =>
      axiosInstance.get(`/v2/deals/${deals_id}/menus/${menu_id}/menu_items`).then((res) => ({
        menu_id,
        data: res.data.data,
      }))
    );

    const response = await Promise.all(promises);

    return { response, lastFetch: deals_id };
  }
);
