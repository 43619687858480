type SvgProps = {
  fill?: string;
};

export const Delivery = ({ fill = '#333333' }: SvgProps) => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.60791 0.375618C10.67 0.622072 11.0763 1.24311 11.5856 2.0979H9.60791V0.375618ZM3.40104 2.16968C3.60899 2.16968 3.75993 2.27005 3.75993 2.50464C3.75993 2.70249 3.59983 2.86296 3.40207 2.86353H1.23389C1.03566 2.86353 0.875 3.02428 0.875 3.22241C0.875 3.42064 1.03566 3.5813 1.23389 3.5813H4.46387C4.66397 3.5813 4.82416 3.74186 4.82416 3.94019C4.82416 4.13841 4.6635 4.29907 4.46527 4.29907H1.23389C1.03566 4.29907 0.875 4.45973 0.875 4.65796C0.875 4.85619 1.03566 5.01685 1.23389 5.01685H2.02344V6.09351C2.02344 6.29173 2.1841 6.45239 2.38232 6.45239H3.10374C3.23234 7.08587 3.79208 7.55298 4.4519 7.55298C5.11173 7.55298 5.67146 7.08587 5.80007 6.45239H9.44407C9.57268 7.08587 10.1324 7.55298 10.7922 7.55298C11.4521 7.55298 12.0118 7.08587 12.1404 6.45239H12.7661C12.9643 6.45239 13.125 6.29173 13.125 6.09351V3.94019C13.125 2.88717 12.0143 2.81726 12.0133 2.81567H9.24902C9.05079 2.81567 8.89014 2.65502 8.89014 2.45679V0.303467H2.38232C2.1841 0.303467 2.02344 0.464125 2.02344 0.662354V1.4519H1.59277C1.39454 1.4519 1.23389 1.61256 1.23389 1.81079C1.23389 2.00902 1.39454 2.16968 1.59277 2.16968H3.40104ZM11.2575 5.712C11.5144 5.96892 11.5144 6.38566 11.2575 6.64258C10.8442 7.05586 10.1343 6.76184 10.1343 6.17725C10.1343 5.59275 10.8442 5.29872 11.2575 5.712ZM4.91715 5.712C5.17407 5.96892 5.17407 6.38566 4.91715 6.64258C4.50387 7.05586 3.79395 6.76184 3.79395 6.17725C3.79395 5.59275 4.50387 5.29872 4.91715 5.712Z"
      fill={fill}
    />
  </svg>
);

export const Paid = ({ fill = '#333333' }: SvgProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 0C3.14019 0 0 3.14019 0 7C0 10.8598 3.14019 14 7 14C10.8598 14 14 10.8598 14 7C14 3.14019 10.8598 0 7 0ZM10.279 5.46386L6.18263 9.56025C5.90012 9.84284 5.44124 9.84304 5.15856 9.56025L3.72099 8.12263C3.43819 7.83983 3.43819 7.38136 3.72099 7.09856C4.00378 6.81577 4.4623 6.81577 4.74505 7.09856L5.6706 8.02411L9.25491 4.4398C9.53766 4.15704 9.99618 4.15704 10.279 4.4398C10.5618 4.72255 10.5618 5.18106 10.279 5.46386Z"
      fill={fill}
    />
  </svg>
);

export const Pickup = ({ fill = '#333333' }: SvgProps) => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5619 3.55335C13.5616 3.54153 13.5611 3.52976 13.5601 3.51813C13.5594 3.51025 13.5586 3.50236 13.5576 3.49451C13.5564 3.48451 13.5548 3.47464 13.5531 3.46486C13.5513 3.45516 13.5492 3.44548 13.547 3.43584C13.5451 3.42801 13.5431 3.42021 13.541 3.41246C13.5378 3.40132 13.5343 3.39023 13.5304 3.37917C13.529 3.37534 13.5281 3.37154 13.5267 3.36771L12.5451 0.75L11.9443 0.75L11.2798 0.75L2.72023 0.75L2.05571 0.75L1.45493 0.75L0.473291 3.36768C0.471878 3.37151 0.470954 3.37531 0.469622 3.37915C0.465736 3.39021 0.462203 3.40132 0.459051 3.41244C0.45685 3.42016 0.454893 3.42796 0.453045 3.43581C0.450708 3.44548 0.448697 3.45516 0.44693 3.46483C0.445164 3.47462 0.443642 3.48448 0.442392 3.49448C0.441413 3.50236 0.440517 3.51025 0.439891 3.5181C0.438913 3.52976 0.438342 3.5415 0.438098 3.55332C0.438016 3.55726 0.4375 3.56115 0.4375 3.56517L0.4375 5.81518C0.4375 6.1258 0.689343 6.37768 0.999996 6.37768H1.56249L1.56249 8.85175L1.56249 11.1018C1.56249 11.4124 1.81433 11.6643 2.12499 11.6643L7.75 11.6643H11.875C12.1857 11.6643 12.4375 11.4124 12.4375 11.1018V6.3777H13C13.3107 6.3777 13.5625 6.1258 13.5625 5.81521V3.5652C13.5625 3.56117 13.562 3.55731 13.5619 3.55335ZM2.68748 6.3777L7.18748 6.3777V8.28926H2.68748L2.68748 6.3777ZM7.18748 10.5393L2.68748 10.5393L2.68748 9.41428L7.1875 9.41428V10.5393H7.18748ZM11.3125 10.5393H8.31249L8.31249 8.85175V6.37768L11.3125 6.37768V10.5393Z"
      fill={fill}
    />
  </svg>
);
