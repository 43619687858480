import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from '@redux/store';

// ----------------------------------------------------------------------

type CouponStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: null;
  data: any[];
  coupon: any;
};

const initialState: CouponStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [],
  coupon: null,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SET COUPON
    getCouponSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    getCouponByIdSuccess(state, action) {
      state.isLoading = false;
      state.coupon = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const useCoupon = () => useSelector((RootState) => RootState.coupon);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  getCouponSuccess,
  getCouponByIdSuccess,
  reset,
} = slice.actions;
