import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
//
import Head from 'next/head';
import Logo from '../images/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <RootStyle>
      <Head>
        <title>Upsell</title>
      </Head>

      <m.div
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Logo sx={{ width: 128, height: 128 }} />
      </m.div>
    </RootStyle>
  );
}
