import crypto from 'crypto';
import jwtDecode from 'jwt-decode';
import { RoleType } from 'src/@types/v2/roles';
import { OrgIdToOrgInfoType, OrgInfoType } from 'src/@types/v2/team-management';
import { PATH_DASHBOARD } from '@routes/paths';
import { AuthUserType } from './types/auth';
import { FullPermissionsType } from './types/permissions';

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const algorithm = 'aes-256-cbc';
const pinKey = process.env.NEXT_PUBLIC_PIN_ENCRYPTION_KEY;

const decryptPIN = (text: string) => {
  if (!text || pinKey === undefined) return '';

  const [iv, encryptedText] = text.split(':').map((part) => Buffer.from(part, 'hex'));
  const decipher = crypto.createDecipheriv(algorithm, Buffer.from(pinKey, 'hex'), iv);

  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString('utf-8');
};

const setSessionAsync = async (
  data: {
    accessToken: string | null;
    activeOrgName: string | null;
    activeStore: string | null;
    globalBranchIds: string[] | null;
  } | null
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    if (!data) {
      localStorage.clear();
      resolve();
      return;
    }

    const { accessToken, activeOrgName, activeStore, globalBranchIds } = data;

    const sessionData = {
      accessToken,
      activeOrgName,
      activeStore,
      globalBranchIds: globalBranchIds ? JSON.stringify(globalBranchIds || []) : null,
    };

    try {
      Object.entries(sessionData).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          localStorage.setItem(key, value);
        } else {
          localStorage.removeItem(key);
        }
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });

const setSession = (accessToken: string | null) => {
  const sessionData = {
    accessToken,
  };

  Object.entries(sessionData).forEach(([key, value]) => {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  });
};

const getRoleData = (user: AuthUserType): RoleType | null => {
  if (!user) {
    return null;
  }

  const selectedStore = user.stores.find((curStore) => curStore.primary);
  if (!selectedStore) {
    return null;
  }

  const role = user.roles.find((curRole) => curRole.store === selectedStore.store._id);
  if (!role) {
    return null;
  }
  return role.role;
};

const getActiveOrg = async (
  orgIdToOrgInfo: OrgIdToOrgInfoType,
  storeId: string
): Promise<OrgInfoType | null> => {
  const activeOrg = Object.values(orgIdToOrgInfo).find(
    (org) => org.orgMetadata.store_id === storeId
  );

  return activeOrg || null;
};

const redirectToKDS = (
  currentPath: string,
  userPermissions: FullPermissionsType[]
): string | null => {
  const redirect =
    userPermissions.includes('dashboard::order_display::read') &&
    !userPermissions.includes('dashboard::reports::read::limited');

  if (redirect && currentPath === PATH_DASHBOARD.general.app) {
    return PATH_DASHBOARD.orderDisplay.root;
  }
  return null;
};

export {
  isValidToken,
  getActiveOrg,
  decryptPIN,
  getRoleData,
  redirectToKDS,
  setSessionAsync,
  setSession,
};
