import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { InventoryCategoryType, InventoryDataType } from 'src/@types/v2/inventory';

// ----------------------------------------------------------------------

type InventoryStateType = {
  isLoading: boolean;
  error: boolean;
  data: InventoryDataType;
  setting: {
    categories: InventoryCategoryType[];
  };
};

const initialState: InventoryStateType = {
  isLoading: false,
  error: false,
  data: {
    product: {
      list: [],
      sortBy: 'name-desc',
    },
    variation: {
      list: [],
      sortBy: 'name-desc',
    },
  },
  setting: {
    categories: [],
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SET PRODUCT Inventory
    setProductInventoryData(state, action) {
      state.isLoading = false;
      state.data.product.list = action.payload;
    },

    // SET VARIATION
    setVariationInventoryData(state, action) {
      state.isLoading = false;
      state.data.variation.list = action.payload;
    },

    // SET CATEGORIES
    setSettingCategories(state, action) {
      state.isLoading = false;
      state.setting.categories = action.payload;
    },

    // SORT INVENTORY
    sortByInventory(
      state,
      action: {
        payload: {
          type: 'product' | 'variation';
          sortBy: string;
        };
      }
    ) {
      const { type, sortBy } = action.payload;
      switch (type) {
        case 'product':
          state.data.product.sortBy = sortBy;
          break;
        case 'variation':
          state.data.variation.sortBy = sortBy;
          break;
        default:
          break;
      }
    },
  },
});

export const useInventory = (): InventoryStateType =>
  useSelector((RootState: { inventory: InventoryStateType }) => RootState.inventory);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  sortByInventory,
  hasError,
  setProductInventoryData,
  setSettingCategories,
  setVariationInventoryData,
} = slice.actions;
