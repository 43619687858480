/* eslint-disable import/no-duplicates */
import '../locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// fullcalendar
import '@fullcalendar/common/main.min.css';
import '@fullcalendar/daygrid/main.min.css';

import { ReactElement, ReactNode } from 'react';
// next
import { NextPage } from 'next';
import Head from 'next/head';
import { AppProps } from 'next/app';
//
import { Provider as ReduxProvider } from 'react-redux';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// redux
import { setDefaultOptions } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { PrinterProvider } from 'src/contexts/PrinterContext';
import { PrintQueueProvider } from 'src/contexts/PrintQueueContext';
import GrowthBookUpsellProvider from '@components/growthbook/GrowthBookUpsellProvider';
import { AxiosProvider } from 'src/auth/AxiosContext';
import { SettingsProvider, SettingsValueProps, ThemeSettings } from '@components/settings';
import SnackbarProvider from '@components/snackbar';
import { AuthProvider } from 'src/auth/propel/PropelAuthContext';
// import { AuthProvider } from '../auth/JwtContext';
import { store } from '../redux/store';
// contexts
import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext';
// theme
import ThemeProvider from '../theme';
// components
import { StyledChart } from '../components/chart';
import ProgressBar from '../components/progress-bar/ProgressBar';
import MotionLazyContainer from '../components/animate/MotionLazyContainer';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

// ----------------------------------------------------------------------
setDefaultOptions({ locale: enGB });

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  settings: SettingsValueProps;
  Component: NextPageWithLayout;
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <AuthProvider>
        <GrowthBookUpsellProvider>
          <AxiosProvider
            serverUrl={process.env.NEXT_PUBLIC_API_ENDPOINT}
            newServerUrl={process.env.NEXT_PUBLIC_NEW_API_ENDPOINT}
          >
            <ReduxProvider store={store}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                <CollapseDrawerProvider>
                  <SettingsProvider>
                    <MotionLazyContainer>
                      <ThemeProvider>
                        <ThemeSettings>
                          <SnackbarProvider>
                            <PrintQueueProvider>
                              <PrinterProvider>
                                <StyledChart />
                                <ProgressBar />
                                {getLayout(<Component {...pageProps} />)}
                              </PrinterProvider>
                            </PrintQueueProvider>
                          </SnackbarProvider>
                        </ThemeSettings>
                      </ThemeProvider>
                    </MotionLazyContainer>
                  </SettingsProvider>
                </CollapseDrawerProvider>
              </LocalizationProvider>
            </ReduxProvider>
          </AxiosProvider>
        </GrowthBookUpsellProvider>
      </AuthProvider>
    </>
  );
}
