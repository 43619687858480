import React, { createContext, useContext } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { usePropelAuthContext } from 'src/auth/useAuthContext';
import localStorageAvailable from '@utils/template/localStorageAvailable';

// Create a context for the axios instance
const AxiosContext = createContext<AxiosInstance | null>(null);

type Props = {
  children: React.ReactNode;
  serverUrl?: string;
  newServerUrl?: string;
};

// Create a provider component that wraps the app and provides the axios instance
export const AxiosProvider = ({ children, serverUrl, newServerUrl }: Props) => {
  const { currentStoreId } = usePropelAuthContext();
  const storageAvailable = localStorageAvailable();
  const accessToken = storageAvailable ? localStorage.getItem('accessToken') : null;
  const useNewServer = useFeatureIsOn('use-new-server');

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    ...(currentStoreId ? { 'selected-store': currentStoreId } : {}),
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
    headers.Source = 'pos';
  }

  const axiosInstance = axios.create({
    baseURL: useNewServer ? newServerUrl : serverUrl,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  );

  return <AxiosContext.Provider value={axiosInstance}>{children}</AxiosContext.Provider>;
};

// Create a hook to access the axios instance from any component
export const useAxios = () => {
  const axiosInstance = useContext(AxiosContext);
  if (!axiosInstance) {
    throw new Error('useAxios must be used within an AxiosProvider');
  }
  return axiosInstance;
};
