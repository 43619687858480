import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { SettingType } from 'src/@types/v2/settings/settings';
import { StoreType } from 'src/@types/v2/stores';
import type { BranchType } from 'src/@types/v2/settings/branch';

// ----------------------------------------------------------------------

type SettingsStateType = {
  loading: {
    main: boolean;
    activation: boolean;
  };
  error: boolean;
  setting: SettingType;
  store: StoreType;
  subscriptions: any[];
  branches: BranchType[];
  sortBy: string | null;
};

const initialState: SettingsStateType = {
  loading: {
    main: false,
    activation: false,
  },
  error: false,
  store: {} as StoreType,
  setting: {
    _id: '',
    api_key: '',
    api_secret: '',
    logo: '',
    business_name: '',
    phone: '',
    email: '',
    language: '',
    working_hours: null,
    address: '',
    preferences: {
      currency: '',
      time_zone: '',
      time_format: '',
      order_prefix: '',
      order_sufix: '',
      order_status_received: '',
      order_status_processing: '',
      order_status_ready_deliver: '',
      order_status_ready_pick_up: '',
      order_status_shipped: '',
      order_status_completed: '',
    },
    receipt: {
      company_phone: false,
      company_ssn: false,
      company_vat: false,
      waiter_name: false,
      name: false,
      ssn: false,
      table: false,
      additional_text: '',
    },
    tax: {
      rate: 0,
      include_receipt: false,
      country: '',
      calculation_options: {
        product_prices_include_tax: false,
        apply_discount_after_tax: false,
      },
    },
    notification: {
      daily_sales_summary: false,
      monthly_and_annual_sales_summary: false,
      upsell_updates: false,
      order_received: false,
      per_payment: false,
      per_refund: false,
      per_cancel: false,
      order_confirmation: false,
      order_canceled: false,
      order_refunded: false,
      abandoned_cart: false,
    },
    pickup: {
      waiting_time: 0,
      additional_cost: [
        {
          active: false,
          cost_type: '',
          amount: 0,
          name: '',
          _id: '',
        },
      ],
    },
    delivery: {
      waiting_time: 0,
      additional_cost: [
        {
          active: false,
          cost_type: '',
          amount: 0,
          name: '',
          _id: '',
        },
      ],
      delivery_fee: {
        active: false,
        minimum_order: 0,
        amount: 0,
      },
    },
    open: false,
  },
  subscriptions: [],
  branches: [],
  sortBy: null,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = true;
          break;
        case 'activation':
          state.loading.activation = true;
          break;
        default:
          break;
      }
    },

    // STOP LOADING
    stopLoading(state, action) {
      switch (action.payload) {
        case 'main':
          state.loading.main = false;
          break;
        case 'activation':
          state.loading.activation = false;
          break;
        default:
          break;
      }
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading.main = false;
      state.loading.activation = false;
      state.error = action.payload;
    },

    // SET SETTING DATA
    setSetting(state, action) {
      state.loading.main = false;
      state.loading.activation = false;
      state.setting = action.payload;
    },

    // SET STORE
    setStore(state, action) {
      state.loading.main = false;
      state.store = action.payload;
    },

    // SET TOGGLE OPEN SETTINGS
    setToggleOpenSetting(state) {
      state.setting.open = !state.setting.open;
    },

    // SET SUBSCRIPTION
    setSubscriptions(state, action) {
      state.loading.main = false;
      state.subscriptions = action.payload;
    },

    // SET ALL BRANCHES
    setBranches(state, action) {
      state.loading.main = false;
      state.branches = action.payload;
    },
  },
});

export const useSettings = (): SettingsStateType =>
  useSelector((RootState: { settings: SettingsStateType }) => RootState.settings);

// Reducer
export default slice.reducer;

// Actions
export const { setSetting, startLoading, hasError, setSubscriptions, stopLoading, setBranches } =
  slice.actions;
