import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { DealType } from 'src/@types/v2/deals';

// ----------------------------------------------------------------------

export type DealsStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  currentDeal: DealType | null;
  data: DealType[];
};

const initialState: DealsStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  currentDeal: null,
  data: [] as DealType[],
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET DEALS
    setDealsData(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    // SORT DEALS
    sortByDeals(state, action) {
      state.sortBy = action.payload;
    },
    setCurrentDeal(state, action) {
      state.isLoading = false;
      state.currentDeal = action.payload;
    },
  },
});

export const useDeals = (): DealsStateType =>
  useSelector((RootState: { deals: DealsStateType }) => RootState.deals);

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, hasError, setDealsData, setCurrentDeal, sortByDeals } =
  slice.actions;
