import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { FormattedOrderType, MenuBarType } from 'src/@types/v2/kitchen';
import { OrderType } from 'src/@types/v2/orders/list';

// ----------------------------------------------------------------------

type KitchenStateType = {
  isLoading: boolean;
  selectedOrderBar: string;
  selectedOrderNumber: number;
  menuBar: MenuBarType[];
  ticketsData: FormattedOrderType[];
  activeTickets: FormattedOrderType[];
  readyTickets: FormattedOrderType[];
  scheduledTickets: OrderType[];
  cancelledTickets: FormattedOrderType[];
  selectedSettings: string;
  menuSettings: any[];
  allTickets: FormattedOrderType[];
  mode: string;
  ticketPanel: string;
  layout: string;
  isError: false;
  preventRefetch: false;
};

const initialState: KitchenStateType = {
  isLoading: true,
  selectedOrderBar: 'Active',
  layout: 'compact', // ticket or compact
  selectedOrderNumber: 0,
  menuSettings: [],
  menuBar: [],
  ticketsData: [],
  activeTickets: [],
  readyTickets: [],
  scheduledTickets: [],
  cancelledTickets: [],
  ticketPanel: 'Active',
  allTickets: [],
  selectedSettings: 'Timers and Alerts',
  mode: '',
  isError: false,
  preventRefetch: false,
};

// ----------------------------------------------------------------------

const ReduxSlice = createSlice({
  name: 'KITCHEN_SCREEN',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.isError = action.payload;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    setSelectedOrderBar: (state, action) => {
      state.selectedOrderBar = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setSelectedOrderNumber: (state, action) => {
      state.selectedOrderNumber = action.payload;
    },
    setSelectedSettings: (state, action) => {
      state.selectedSettings = action.payload;
    },
    setMenuSettings: (state, action) => {
      state.menuSettings = action.payload;
    },
    setMenuBar: (state, action) => {
      state.menuBar = action.payload;
    },
    setDataTickets: (state, action) => {
      state.ticketsData = action.payload;
      state.isLoading = false;
    },
    setAllTickets: (state, action) => {
      state.allTickets = action.payload;
      state.isLoading = false;
    },
    setOrderCompleted: (state, action) => {
      state.ticketsData[0].orderList[action.payload.index].completed = action.payload.completed;
    },
    setItemCompleted: (state, action) => {
      state.ticketsData[0].orderList[action.payload.orderIndex].items[action.payload.itemIndex] =
        action.payload.checked;
    },
    setTicketPanel: (state, action) => {
      state.ticketPanel = action.payload;
      // state.isLoading = false;
    },
    setActiveTickets: (state, action) => {
      state.activeTickets = action.payload;
      state.isLoading = false;
    },
    setReadyTickets: (state, action) => {
      state.readyTickets = action.payload;
      state.isLoading = false;
    },
    setScheduledTickets: (state, action) => {
      state.scheduledTickets = action.payload;
      state.isLoading = false;
    },
    setCancelledTickets: (state, action) => {
      state.cancelledTickets = action.payload;
      state.isLoading = false;
    },
    setChangeLayout: (state, action) => {
      state.layout = action.payload;
      localStorage.setItem('order-display-layout', action.payload);
    },
    updateTicket: (state, action) => {
      const { ticket_id } = action.payload;

      const ticketIndex = state.activeTickets.findIndex((ticket) => ticket.ticket_id === ticket_id);

      if (ticketIndex !== -1) {
        state.activeTickets[ticketIndex] = action.payload;
      }
    },
    setPreventRefetch: (state, action) => {
      state.preventRefetch = action.payload;
    },
  },
});

export const {
  stopLoading,
  setSelectedOrderBar,
  setSelectedOrderNumber,
  setMenuBar,
  setDataTickets,
  setOrderCompleted,
  setItemCompleted,
  setAllTickets,
  setMode,
  setSelectedSettings,
  setMenuSettings,
  setTicketPanel,
  startLoading,
  setActiveTickets,
  setReadyTickets,
  setScheduledTickets,
  setCancelledTickets,
  setChangeLayout,
  setError,
  updateTicket,
  setPreventRefetch,
} = ReduxSlice.actions;

export const useKitchen = (): KitchenStateType =>
  useSelector((RootState: { kitchen: KitchenStateType }) => RootState.kitchen);

export default ReduxSlice.reducer;
