import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from '@redux/store';
import { VariantType } from 'src/@types/v2/variants';

// ----------------------------------------------------------------------

type VariantStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: VariantType[];
  isSubmitting: boolean;
};

const initialState: VariantStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [] as VariantType[],
  isSubmitting: false,
};
// ----------------------------------------------------------------------

const variantSlice = createSlice({
  name: 'variants',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isSubmitting = false;
    },
    // GET CATEGORY
    getVariantsSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.isSubmitting = false;
    },
    // SORT & FILTER VARIANT
    sortByVariant(state, action) {
      state.sortBy = action.payload;
    },
    setSubmitting(state, action) {
      state.isSubmitting = action.payload;
    },
  },
});

export const useVariants = (): VariantStateType => useSelector((RootState) => RootState.variants);

// Reducer
export default variantSlice.reducer;

// Actions
export const { startLoading, hasError, getVariantsSuccess, sortByVariant, setSubmitting } =
  variantSlice.actions;
