import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from '@redux/store';
import { BranchSettingType, BranchType } from 'src/@types/v2/settings/branch';

// ----------------------------------------------------------------------

type BranchStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  adminBranches: BranchType[];
  fullBranches: BranchType[];
  currentBranch: BranchType | null;
  currentBranchSetting: BranchSettingType | null;
};

const initialState: BranchStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  adminBranches: [],
  fullBranches: [],
  currentBranch: null,
  currentBranchSetting: null,
};

// ----------------------------------------------------------------------

const branchSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setAdminBranches(state, action) {
      state.isLoading = false;
      state.adminBranches = action.payload;
    },
    setFullBranches(state, action) {
      state.isLoading = false;
      state.fullBranches = action.payload;
    },
    setCurrentBranch(state, action) {
      state.isLoading = false;
      state.currentBranch = action.payload;
    },
    setCurrentBranchSetting(state, action) {
      state.isLoading = false;
      state.currentBranchSetting = action.payload;
    },
  },
});

export const useBranches = () =>
  useSelector((RootState: { branches: BranchStateType }) => RootState.branches);

// Reducer
export default branchSlice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  setCurrentBranchSetting,
  setAdminBranches,
  setFullBranches,
  setCurrentBranch,
} = branchSlice.actions;
