// This file is used to store functions which was previously used but not used anymore due to changes in the backend
// These are used temporarily to support, for example, orders who aren't adapted to the new structure yet
import type { OrderType } from 'src/@types/v2/orders/list';

const waitingTimeLegacyFallback = (order: OrderType) =>
  order.order_method === 'delivery'
    ? order.branch.settings.delivery.waiting_time
    : order.branch.settings.pickup.waiting_time;

export default waitingTimeLegacyFallback;
