import { BoxProps, Box } from '@mui/material';
import Lottie from 'lottie-react';
import logo from '../../lotties/spinner.json';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <Lottie loop autoplay animationData={logo} />
    </Box>
  );
}
