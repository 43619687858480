import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RoleType, TeamMemberType } from 'src/@types/v2/team-management';

// ----------------------------------------------------------------------

type TeamManagementStateType = {
  isLoading: boolean;
  members: {
    data: TeamMemberType[];
    pagination: {
      total: number;
      page: number;
      limit: number;
      has_more_results: boolean;
    };
  };
  member: TeamMemberType | null;
  roles: RoleType[];
  sortBy: string;
  sortByRole: string;
  error: boolean;
};

const initialState: TeamManagementStateType = {
  isLoading: false,
  members: {
    data: [],
    pagination: {
      total: 0,
      page: 1,
      limit: 10,
      has_more_results: false,
    },
  },
  member: null,
  sortBy: 'createdAt-desc',
  sortByRole: 'hierarchy-desc',
  roles: [
    {
      role: 'Owner',
      memberCount: 0,
    },
    {
      role: 'Manager',
      memberCount: 0,
    },
    {
      role: 'Branch Manager',
      memberCount: 0,
    },
    {
      role: 'Chef/Head Kitchen',
      memberCount: 0,
    },
    {
      role: 'Prep Cook',

      memberCount: 0,
    },
    {
      role: 'Cashier',
      memberCount: 0,
    },
    {
      role: 'Waitstaff/Server',
      memberCount: 0,
    },
    {
      role: 'Bartender',
      memberCount: 0,
    },
  ],
  error: false,
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'team-management',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Set Team member
    setTeamMembers(
      state,
      action: {
        payload: {
          data: TeamMemberType[];
          pagination?: {
            total: number;
            page: number;
            limit: number;
            has_more_results: boolean;
          };
        };
      }
    ) {
      const { data, pagination } = action.payload;
      state.isLoading = false;
      state.members.data = data;
      if (pagination) {
        state.members.pagination = pagination;
      }
    },

    // Set member
    setMember(state, action) {
      state.isLoading = false;
      state.member = action.payload;
    },

    // Set roles data
    setRoles(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },

    // SORT TEAM Members
    sortByTeamMembers(state, action) {
      state.sortBy = action.payload;
    },

    // Sort by role
    sortByRole(state, action) {
      state.sortByRole = action.payload;
    },
  },
});

export const useTeamManagement = (): TeamManagementStateType =>
  useSelector((RootState: { teamManagement: TeamManagementStateType }) => RootState.teamManagement);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  sortByTeamMembers,
  sortByRole,
  stopLoading,
  setMember,
  setTeamMembers,
  setRoles,
  hasError,
} = slice.actions;
