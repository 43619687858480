import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ProductType } from 'src/@types/v2/products';
import { ProductFormProps } from 'src/validations/restaurant/product/product-form';

// ----------------------------------------------------------------------

type ProductsStateType = {
  isLoading: boolean;
  error: boolean;
  sortBy: string | null;
  data: ProductType[];
  product: ProductFormProps | null;
  isSubmitting: boolean;
  variationLayout: string;
};

const initialState: ProductsStateType = {
  isLoading: false,
  error: false,
  sortBy: null,
  data: [] as ProductType[],
  product: null,
  isSubmitting: false,
  variationLayout: 'grid',
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
      state.isSubmitting = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isSubmitting = false;
    },
    // SET PRODUCTS
    setProductData(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    // SET CURRENT PRODUCT
    setCurrentProduct(state, action) {
      state.product = action.payload;
      state.isLoading = false;
    },
    changeVariationLayout(state, action) {
      state.variationLayout = action.payload;
    },
    // Sort Product
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    setSubmitting(state, action) {
      state.isSubmitting = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const useProducts = (): ProductsStateType =>
  useSelector((RootState: { products: ProductsStateType }) => RootState.products);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  setCurrentProduct,
  setProductData,
  sortByProducts,
  changeVariationLayout,
  reset,
  setSubmitting,
} = slice.actions;
