import { format } from 'date-fns';
import { addMinutesToDate } from '@utils/date-time';
import type { OrderDetailType, OrderType, ExtrasType } from '../../@types/v2/orders/list';
import { Left as SplitPizzaLeft, Right as SplitPizzaRight } from '../../assets/svg/SplitPizzaSvg';
import { Delivery, Paid, Pickup } from '../../assets/svg/ReceiptSvg';
import { BlackWithText } from '../../assets/svg/UpsellLogoSvg';

type HeaderProps = {
  customer: OrderType['customer'];
  store: OrderType['store'];
  method: OrderType['order_method'];
  number: OrderType['order_number'];
  branch: OrderType['branch'];
  createdAt: OrderType['createdAt'];
  paid: boolean;
  mode: 'order' | 'receipt';
  waitingTime: number;
  ssn?: string;
};

type DeliveryProps = {
  shipping: OrderType['shipping'];
};

type NoteProps = {
  notes: OrderType['order_notes'];
};

type BodyProps = {
  currency: OrderType['currency'];
  items: OrderType['items'];
  additionalCosts: OrderType['additional_cost'];
  total: OrderType['total'];
  sub_total: OrderType['sub_total'];
  discount_total: OrderType['discount_total'];
  tax_total: OrderType['tax_total'];
  paid: boolean;
  mode: 'order' | 'receipt';
  debug_orderNumber?: OrderType['order_number'];
};

type ItemProps = {
  item: OrderDetailType;
  paid: boolean;
  mode?: 'order' | 'receipt';
};

type MethodBarProps = {
  method: OrderType['order_method'];
};

// handle category priority listing here
// First index: highest priority
// Last index: lowest priority
const PRIORITIZED_CATEGORIES = ['Deals', 'Split Pizza', 'Pizza', 'Meðlæti', 'Sósur', 'Drykkir'];
export const PRIORITIZED_EXTRAS = ['Grænmeti & ávextir', 'Krydd', 'Ostur', 'Kjötálegg', 'Sósur'];

export const Header = ({
  customer,
  method,
  number,
  branch,
  store,
  paid,
  mode,
  createdAt,
  waitingTime,
  ssn,
}: HeaderProps) => {
  const estimationWaitingTime =
    method === 'delivery'
      ? branch.settings.delivery.waiting_time || 0
      : branch.settings.pickup.waiting_time || 0;

  const estimatedTime = addMinutesToDate(new Date(createdAt), waitingTime ?? estimationWaitingTime);

  const waitingTimeInterval = waitingTime >= 60 ? 10 : 5;

  let methodText;

  switch (method) {
    case 'delivery':
      methodText = 'Delivery at';
      break;
    case 'pickup':
      methodText = 'Pick up at';
      break;
    default:
      methodText = 'Serve at';
      break;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '14px 14px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '10px',
          paddingBottom: '12px',
        }}
      >
        {store?.receipt_logo && store?.receipt_logo.url ? (
          <img
            src={store?.receipt_logo.url}
            alt={store?.receipt_logo.alt || 'logo'}
            style={{
              maxWidth: '50%',
              maxHeight: '200px',
              paddingBottom: '12px',
            }}
          />
        ) : (
          <BlackWithText />
        )}
        <div
          style={{
            paddingTop: '12px',
            fontWeight: 700,
          }}
        >
          {branch?.business_name}
        </div>
        {store?.business_ssn && <div>{`Kennitala: ${store.business_ssn}`}</div>}
        <div>{`${branch?.address?.address}, ${branch?.address?.city} ${branch?.address?.postcode}`}</div>
        {store?.vat_number && <div>{`VAT number: ${store.vat_number}`}</div>}
      </div>
      {mode === 'receipt' && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
              padding: '12px 0',
            }}
          >
            <div>{/* Waiter */}</div>
            <div>{format(new Date(createdAt), 'dd-MM-yy, HH:mm')}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
            }}
          >
            <div>Order Type: {method}</div>
            <div>{`Inv: #00${number}`}</div>
          </div>
        </>
      )}
      {mode === 'order' && (
        <div
          style={{
            borderBottom: '1px dashed #D4D4D4',
            padding: '1px',
            width: '100%',
          }}
        />
      )}
      <div
        style={{
          fontWeight: 700,
          fontSize: '12px',
          padding: '12px 0',
        }}
      >
        {`Order #${number}`}
      </div>
      {mode === 'receipt' ? (
        <>
          <div
            style={{
              borderBottom: '1px dashed #D4D4D4',
              padding: '1px',
              width: '100%',
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
              padding: '12px 0',
            }}
          >
            <div>{/* Customer: {customer?.name} */}</div>
            <div
              style={{
                fontSize: '12px',
              }}
            >
              {customer?.ssn || ssn}
            </div>
          </div>
          {/* <div
            style={{
              width: '100%',
              fontSize: '10px',
            }}
          >
            Table: 01
          </div> */}
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
              paddingBottom: '12px',
            }}
          >
            <div
              style={{
                fontWeight: 400,
              }}
            >
              Date & Time
            </div>
            <div
              style={{
                fontWeight: 500,
              }}
            >
              {format(new Date(createdAt), 'dd-MM-yy, HH:mm')}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
              paddingBottom: '12px',
            }}
          >
            <div
              style={{
                fontWeight: 400,
              }}
            >
              Customer
            </div>
            <div
              style={{
                fontWeight: 500,
              }}
            >
              {customer?.name}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
              paddingBottom: '12px',
            }}
          >
            <div
              style={{
                fontWeight: 400,
              }}
            >
              Phone
            </div>
            <div
              style={{
                fontWeight: 500,
              }}
            >
              {customer?.phone.phone_number}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
              paddingBottom: '12px',
            }}
          >
            <div
              style={{
                fontWeight: 400,
              }}
            >
              Method
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '10px',
              }}
            >
              {method === 'pickup' ? <Pickup /> : <Delivery />}
              <div
                style={{
                  paddingLeft: '6px',
                  fontWeight: 500,
                }}
              >
                {method}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              width: '100%',
              paddingBottom: `${method === 'dine-in' || paid ? '12px' : 0}`,
            }}
          >
            <div
              style={{
                fontWeight: 400,
              }}
            >
              {methodText}
            </div>
            <div
              style={{
                fontWeight: 500,
              }}
            >
              {/* estimated time - estimated time + 5 minutes */}
              {`${format(estimatedTime, 'HH:mm')} - ${format(
                addMinutesToDate(estimatedTime, waitingTimeInterval),
                'HH:mm'
              )}`}
            </div>
          </div>

          {method === 'dine-in' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '10px',
                width: '100%',
                paddingBottom: `${paid ? '12px' : 0} `,
              }}
            >
              <div>Table</div>
              <div>01</div>
            </div>
          )}

          {paid && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '10px',
                width: '100%',
              }}
            >
              <div>Status</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '10px',
                }}
              >
                <Paid />
                <div
                  style={{
                    paddingLeft: '6px',
                  }}
                >
                  Paid
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const TestBar = () => (
  <div
    style={{
      padding: '8px',
      color: 'white',
      backgroundColor: 'black',
      width: '100%',
      textAlign: 'center',
    }}
  >
    TEST
  </div>
);

export const MethodBar = ({ method }: MethodBarProps) => (
  <div
    style={{
      padding: '8px',
      color: 'white',
      backgroundColor: `${method === 'pickup' ? 'black' : 'white'}`,
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {method === 'pickup' ? <Pickup fill="white" /> : <Delivery fill="black" />}
    <div
      style={{
        paddingLeft: '6px',
        fontWeight: 500,
      }}
    >
      {method}
    </div>
  </div>
);

export const UnpaidBar = () => (
  <div
    style={{
      padding: '8px',
      color: 'white',
      backgroundColor: 'black',
      width: '100%',
      textAlign: 'center',
    }}
  >
    Unpaid
  </div>
);

export const DeliveryAddress = ({ shipping }: DeliveryProps) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      width: '100%',
      padding: '0 14px 12px',
    }}
  >
    <div
      style={{
        borderBottom: '1px dashed #D4D4D4',
        padding: '1px',
        width: '100%',
      }}
    />
    <div
      style={{
        paddingTop: '8px',
        fontWeight: 700,
        fontSize: '12px',
        paddingBottom: '4px',
      }}
    >
      Delivery To
    </div>
    <div
      style={{
        fontSize: '10px',
        width: '100%',
      }}
    >
      {`${shipping?.address}, ${shipping?.postcode}`}
    </div>
    <div
      style={{
        fontSize: '10px',
        width: '100%',
      }}
    >
      {`${shipping.address_detail}`}
    </div>
    {shipping.shipping_note && (
      <div
        style={{
          fontSize: '10px',
          width: '100%',
        }}
      >
        {`NOTE: ${shipping.shipping_note}`}
      </div>
    )}
  </div>
);

export const Note = ({ notes }: NoteProps) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      width: '100%',
      padding: '0 14px',
    }}
  >
    <div
      style={{
        borderBottom: '1px dashed #D4D4D4',
        padding: '1px',
        width: '100%',
      }}
    />
    {notes.map((note, index) => (
      <div
        style={{
          fontSize: '10px',
          width: '100%',
          paddingTop: '8px',
        }}
        key={index}
      >
        {note.note}
      </div>
    ))}
  </div>
);

const SectionDivider = ({ name }: { name: string }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '12px',
    }}
  >
    <div
      style={{
        borderTop: '1px dashed #D4D4D4',
        padding: '1px',
        width: '100%',
      }}
    />
    <div
      style={{
        paddingTop: '8px',
        backgroundColor: 'white',
        fontWeight: 400,
        fontSize: '12px',
      }}
    >
      {name}
    </div>
  </div>
);

// handle items
const Extras = ({ extrasItems }: { extrasItems: ExtrasType['items'] }) => (
  <div>
    {extrasItems.map((item, index) => {
      if (!item.include || item.doubled) {
        return (
          <div
            key={index}
            style={{
              fontWeight: 400,
            }}
          >
            + {item.name} {item.doubled && '(x2)'}
          </div>
        );
      }
      return null;
    })}
  </div>
);

// handle excluded_tiems
const ExtrasInverted = ({ extrasItems }: { extrasItems: ExtrasType['excluded_items'] }) => (
  <div>
    {extrasItems.map(
      (item, index) =>
        item.include && (
          <div
            key={index}
            style={{
              fontWeight: 400,
            }}
          >
            — {item.name} {item.doubled && '(x2)'}
          </div>
        )
    )}
  </div>
);

const DisplayExtras = ({ extras }: { extras: ExtrasType[] }) => {
  // sort extras based on PRIORITIZED_EXTRAS
  const sortedExtras = extras
    ?.slice()
    .sort((a, b) => PRIORITIZED_EXTRAS.indexOf(a.name) - PRIORITIZED_EXTRAS.indexOf(b.name));

  return (
    <>
      {extras &&
        sortedExtras.map((extra, index) => {
          if (extra.excluded_items) {
            return <ExtrasInverted extrasItems={extra.excluded_items} key={index} />;
          }
          return null;
        })}
      {extras &&
        sortedExtras.map((extra, index) => <Extras extrasItems={extra.items} key={index} />)}
    </>
  );
};

const SplitPizza = ({ item, paid, mode }: ItemProps) => (
  <div
    style={{
      paddingBottom: '12px',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '12px',
        width: '100%',
        fontWeight: 700,
        paddingBottom: '2px',
      }}
    >
      <div>1 x Split Pizza</div>
      <div>{paid ? '' : `${item.total} kr`}</div>
    </div>

    <div
      style={{
        paddingBottom: '8px',
        fontWeight: 400,
      }}
    >
      {/* // TO-DO: ADD THESE INTO TYPE SYSTEM 
          // @ts-ignore */}
      {item.split_items[0].variation.name}
    </div>

    <div
      style={{
        display: 'flex',
        flexDirection: `${mode === 'order' ? 'column' : 'row'}`,
      }}
    >
      {
        // TO-DO: ADD THESE INTO TYPE SYSTEM

        item.split_items.map((curItem: any, index: number) => (
          <div
            style={{
              display: 'flex',

              width: `${mode === 'order' ? '100%' : '50%'}`,
              columnGap: '4px',
              paddingBottom: `${index === 0 ? '8px' : 0}`,
            }}
            key={index}
          >
            {index === 0 ? <SplitPizzaLeft /> : <SplitPizzaRight />}
            <div
              style={{
                paddingLeft: '8px',
              }}
            >
              <div
                style={{
                  fontSize: '12px',
                }}
              >
                {curItem.name}
              </div>
              <DisplayExtras extras={curItem.extras} />
            </div>
          </div>
        ))
      }
    </div>
  </div>
);

const Variation = ({ item, paid, mode }: ItemProps) => (
  <div
    style={{
      paddingBottom: '12px',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '12px',
        width: '100%',
        fontWeight: 700,
        paddingBottom: '2px',
      }}
    >
      <div>{`${item.quantity} x ${item.name}`}</div>
      <div>{paid ? '' : `${item.total} kr`}</div>
    </div>
    <div
      style={{
        paddingBottom: '8px',
        fontWeight: 400,
      }}
    >
      {item.variation.name}
    </div>
  </div>
);

const VariationExtras = ({ item, paid, mode }: ItemProps) => (
  <div
    style={{
      paddingBottom: '12px',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '12px',
        width: '100%',
        fontWeight: 700,
        paddingBottom: '2px',
      }}
    >
      <div>{`${item.quantity} x ${item.name}`}</div>
      <div>{paid ? '' : `${item.total} kr`}</div>
    </div>
    <div
      style={{
        paddingBottom: '8px',
        fontWeight: 400,
      }}
    >
      {item.variation.name}
    </div>
    <DisplayExtras extras={item.extras} />
  </div>
);

const SimpleItem = ({ item, paid, mode }: ItemProps) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '12px',
      width: '100%',
      fontWeight: 700,
      paddingBottom: '12px',
    }}
  >
    <div>{`${item.quantity} x ${item.name}`}</div>
    <div>{paid ? '' : `${item.total} kr`}</div>
  </div>
);

// used for 'custom_deals'
const CustomDeals = ({ item, mode = 'order', paid }: ItemProps) => {
  if (mode === 'order') {
    // if you want to handle duplicates like in the main function,
    // do it here
  }

  return (
    <div
      style={{
        paddingBottom: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          width: '100%',
          fontWeight: 700,
          paddingBottom: '12px',
        }}
      >
        <div>{`${item.quantity} x ${item.name}`}</div>
        <div>{paid ? '' : `${item.total} kr`}</div>
      </div>
      <div
      // style={{
      //   marginLeft: '8px',
      // }}
      >
        {item.menus.length > 0 &&
          item.menus.map((curItem, index) => (
            <div key={index}>
              {curItem.menu_items.map((menuItem, menuIndex) =>
                renderItem(menuItem, true, mode, menuIndex)
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

// main rendering function, can be used recursively
const renderItem = (
  item: OrderDetailType,
  paid: boolean,
  mode: 'order' | 'receipt',
  index: number
) => {
  switch (item.type) {
    case 'split_product':
      return <SplitPizza item={item} paid={paid} mode={mode} key={index} />;
    case 'variation_extras':
      return <VariationExtras item={item} paid={paid} key={index} />;
    case 'variation':
      return <Variation item={item} paid={paid} key={index} />;
    case 'custom_deals':
      return <CustomDeals item={item} paid={paid} key={index} mode={mode} />;
    default:
      return <SimpleItem item={item} key={index} paid={paid} />;
  }
};

export const Body = ({
  additionalCosts,
  currency,
  items,
  total,
  paid,
  mode,
  sub_total,
  discount_total,
  tax_total,
  debug_orderNumber,
}: BodyProps) => {
  // map get categories by name, prevent duplicatges
  const categories: string[] = items.reduce((acc, item) => {
    if (item.hasOwnProperty('product_categories')) {
      item.product_categories
        .filter((category) => category.category)
        .forEach((category) => {
          if (category.primary && !acc.includes(category.category.name)) {
            acc.push(category.category.name);
          }
        });
      // handle cases where product doesn't have property 'product_categories'
    } else {
      // if item is a split pizza, add split pizza category
      if (items.some((curItem) => curItem.type === 'deals' || 'custom_deals')) {
        // if item is a deal, add deal category
        if (!acc.includes('Deals')) {
          acc.push('Deals');
        }
      }

      if (items.some((curItem) => curItem.type === 'split_product')) {
        if (!acc.includes('Split Pizza')) {
          acc.push('Split Pizza');
        }
      } else if (!acc.includes('Others')) {
        // finally, if other parameters are not fulfilled add Other category
        acc.push('Others');
      }
    }
    return acc;
  }, [] as string[]);

  // re-sort category based on PRIORITIZED_CATEGORIES
  categories.sort((a, b) => PRIORITIZED_CATEGORIES.indexOf(a) - PRIORITIZED_CATEGORIES.indexOf(b));

  // sort items by category
  // {
  //   category: [items]
  // }
  let sortedItems = categories.reduce(
    (acc, category) => {
      acc[category] = items.filter((item) => {
        if (item.hasOwnProperty('product_categories')) {
          return item.product_categories
            .filter((cat) => cat.category)
            .some((cat) => {
              if (cat.primary) {
                return cat.category.name === category;
              }
              return false;
            });

          // if item.type === "split_product" add to split pizza category
        }
        if (item.type === 'split_product') {
          return category === 'Split Pizza';
          // if item.type === "deals" or "custom_deals" add to deals category
        }
        if (item.type === 'deals' || item.type === 'custom_deals') {
          return category === 'Deals';
          // last fallback is to add to "Other" category
        }
        return category === 'Others';
      });
      return acc;
    },
    {} as { [key: string]: OrderDetailType[] }
  );

  if (mode === 'order') {
    // create duplicates for item with quantity > 1
    const duplicated = Object.keys(sortedItems).reduce(
      (acc, category) => {
        acc[category] = sortedItems[category].reduce((curAcc, item) => {
          for (let i = 0; i < item.quantity; i += 1) {
            // set item quantity to 1
            // divide item.total by quantity
            curAcc.push({
              ...item,
              quantity: 1,
              total: item.total / item.quantity,
            });
          }
          return curAcc;
        }, [] as OrderDetailType[]);
        return acc;
      },
      {} as { [key: string]: OrderDetailType[] }
    );

    // console.log('duplicated', duplicated);
    sortedItems = duplicated;
  }

  return (
    <div
      style={{
        fontSize: '10px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '8px 14px 0',
      }}
    >
      {categories.map((category, index) => (
        <div key={index}>
          <SectionDivider name={category} key={index} />
          {sortedItems[category].length > 0 &&
            sortedItems[category].map((item, curIndex) => renderItem(item, paid, mode, curIndex))}
        </div>
      ))}
      {!paid && (
        <>
          <div
            style={{
              borderBottom: '1px dashed #D4D4D4',
              padding: '1px',
              width: '100%',
            }}
          />
          <div
            style={{
              paddingTop: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              fontWeight: 400,
              width: '100%',
            }}
          >
            <div>Subtotal</div>
            <div>{paid ? '' : `${sub_total} kr`}</div>
          </div>
          {discount_total > 0 && (
            <div
              style={{
                paddingTop: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '10px',
                fontWeight: 400,
                width: '100%',
              }}
            >
              <div>Discount</div>
              <div>{paid ? '' : `- ${discount_total} kr`}</div>
            </div>
          )}
          <div
            style={{
              paddingTop: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '10px',
              fontWeight: 400,
              width: '100%',
            }}
          >
            {/* VAT ARE SUPPOSED TO BE CHANGEABLE, CHANGE THIS LATER */}
            <div>VAT 11%</div>
            <div>{paid ? '' : `${tax_total} kr`}</div>
          </div>
          <div
            style={{
              paddingTop: '12px',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '16px',
              fontWeight: 700,
              width: '100%',
            }}
          >
            <div>Total</div>
            <div>{paid ? '' : `${total} kr`}</div>
          </div>
        </>
      )}
    </div>
  );
};

export const Footer = () => (
  <div
    style={{
      fontSize: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginTop: '12px',
      padding: '14px',
      borderTop: '1px solid #D4D4D4',
    }}
  >
    <div
      style={{
        display: 'flex',
        columnGap: '8px',
        paddingBottom: '8px',
      }}
    >
      <div>Powered by</div>
      <BlackWithText />
    </div>
    <div>Þessi reikningur á uppruna sinn í rafrænu</div>
    <div>reikningakerfi skv. reglugerð nr. 505/2013</div>
  </div>
);
