interface Props {
  width?: number;
  height?: number;
}

export const Left = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#E0E0E0" />
    <path
      d="M8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842857 12.1566 1.96442e-06 10.1217 0 8.00001C-1.96441e-06 5.87828 0.84285 3.84345 2.34314 2.34315C3.84343 0.842862 5.87825 4.02159e-06 7.99998 0L8 8L8 16Z"
      fill="#333333"
    />
  </svg>
);

export const Right = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#E0E0E0" />
    <path
      d="M8 0C10.1217 2.53014e-08 12.1566 0.842854 13.6569 2.34314C15.1571 3.84343 16 5.87826 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6568C12.1566 15.1571 10.1217 16 8.00001 16L8 8V0Z"
      fill="#333333"
    />
  </svg>
);
