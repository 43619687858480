// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  orders: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    // OVERVIEW
    overview: path(ROOTS_DASHBOARD, '/orders/overview'),
    overviewDetails: (branchId: string) =>
      path(ROOTS_DASHBOARD, `/orders/overview/${branchId}/details`),
    // ORDER
    orderNew: path(ROOTS_DASHBOARD, '/orders/new'),
    orderDetails: (orderId: string) => path(ROOTS_DASHBOARD, `/orders/${orderId}/details`),
    orderEdit: (orderId: string) => path(ROOTS_DASHBOARD, `/orders/${orderId}/edit`),
  },
  restaurant: {
    root: path(ROOTS_DASHBOARD, '/restaurant'),
    // PRODUCT
    product: path(ROOTS_DASHBOARD, '/restaurant/product'),
    productEdit: (productId: string) =>
      path(ROOTS_DASHBOARD, `/restaurant/product/${productId}/edit`),
    productNew: path(ROOTS_DASHBOARD, '/restaurant/product/new'),
    // DEALS
    deals: path(ROOTS_DASHBOARD, '/restaurant/deals'),
    dealsNew: path(ROOTS_DASHBOARD, '/restaurant/deals/new'),
    dealsEdit: (dealId: string) => path(ROOTS_DASHBOARD, `/restaurant/deals/${dealId}/edit`),
    // CATEGORY
    category: path(ROOTS_DASHBOARD, '/restaurant/category'),
    categoryNew: path(ROOTS_DASHBOARD, '/restaurant/category/new'),
    categoryEdit: (categoryId: string) =>
      path(ROOTS_DASHBOARD, `/restaurant/category/${categoryId}/edit`),
    // TAG
    tag: path(ROOTS_DASHBOARD, '/restaurant/tag'),
    tagNew: path(ROOTS_DASHBOARD, '/restaurant/tag/new'),
    tagEdit: (tagId: string) => path(ROOTS_DASHBOARD, `/restaurant/tag/${tagId}/edit`),
    // EXTRAS
    extras: path(ROOTS_DASHBOARD, '/restaurant/extras'),
    extrasNew: path(ROOTS_DASHBOARD, '/restaurant/extras/new'),
    extrasEdit: (extrasId: string) => path(ROOTS_DASHBOARD, `/restaurant/extras/${extrasId}/edit`),
    // VARIANT
    variant: path(ROOTS_DASHBOARD, '/restaurant/variant'),
    variantNew: path(ROOTS_DASHBOARD, '/restaurant/variant/new'),
    variantEdit: (variantId: string) =>
      path(ROOTS_DASHBOARD, `/restaurant/variant/${variantId}/edit`),
    // GIFTCARD
    giftcard: path(ROOTS_DASHBOARD, '/restaurant/giftcard'),
    giftcardNewProduct: path(ROOTS_DASHBOARD, '/restaurant/giftcard/product/new'),
    giftcardIssue: path(ROOTS_DASHBOARD, '/restaurant/giftcard/issue'),
    giftcardEditProduct: (giftcardId: string) =>
      path(ROOTS_DASHBOARD, `/restaurant/giftcard/product/edit/${giftcardId}`),
    giftcardEditIssue: (giftcardId: string) =>
      path(ROOTS_DASHBOARD, `/restaurant/giftcard/issue/${giftcardId}`),
    // DISCOUNT
    discount: path(ROOTS_DASHBOARD, '/restaurant/discount'),
    discountNew: path(ROOTS_DASHBOARD, '/restaurant/discount/new'),
    discountEdit: (discountId: string) =>
      path(ROOTS_DASHBOARD, `/restaurant/discount/${discountId}/edit`),
    // COUPONS
    coupons: path(ROOTS_DASHBOARD, '/restaurant/coupons'),
    couponsNew: path(ROOTS_DASHBOARD, '/restaurant/coupons/new'),
    couponsEdit: (couponId: string) =>
      path(ROOTS_DASHBOARD, `/restaurant/coupons/${couponId}/edit`),
  },
  customers: {
    root: path(ROOTS_DASHBOARD, '/customers'),
    new: path(ROOTS_DASHBOARD, '/customers/new'),
    newCompany: path(ROOTS_DASHBOARD, '/customers/company/new'),
    details: (customerId: string) => path(ROOTS_DASHBOARD, `/customers/${customerId}/details`),
    companyDetails: (companyId: string) =>
      path(ROOTS_DASHBOARD, `/customers/company/${companyId}/details`),
    edit: (customerId: string) => path(ROOTS_DASHBOARD, `/customers/${customerId}/edit`),
  },
  inventory: {
    root: path(ROOTS_DASHBOARD, '/inventory'),
    settings: (branchId: string) => path(ROOTS_DASHBOARD, `/inventory/${branchId}/settings`),
  },
  report: {
    root: path(ROOTS_DASHBOARD, '/report'),
    byProduct: path(ROOTS_DASHBOARD, '/report/by-product'),
    byCustomer: path(ROOTS_DASHBOARD, '/report/by-customer'),
    paymentMethods: path(ROOTS_DASHBOARD, '/report/payment-methods'),
    paymentHistory: path(ROOTS_DASHBOARD, '/report/payment-history'),
    fulfillment: path(ROOTS_DASHBOARD, '/report/fulfillment'),
    salesByProduct: path(ROOTS_DASHBOARD, '/report/sales-by-product'),
    salesSummary: path(ROOTS_DASHBOARD, '/report/sales-summary'),
    salesTrend: path(ROOTS_DASHBOARD, '/report/sales-trend'),
    products: path(ROOTS_DASHBOARD, '/report/products'),
    salesByCategory: path(ROOTS_DASHBOARD, '/report/sales-by-category'),
    salesByDeal: path(ROOTS_DASHBOARD, '/report/sales-by-deal'),
    salesByCustomer: path(ROOTS_DASHBOARD, '/report/sales-by-customer'),
    salesBySource: path(ROOTS_DASHBOARD, '/report/sales-by-source'),
    coupon: path(ROOTS_DASHBOARD, '/report/coupon'),
    discount: path(ROOTS_DASHBOARD, '/report/discount'),
    branch: path(ROOTS_DASHBOARD, '/report/branch'),
    extras: path(ROOTS_DASHBOARD, '/report/extras'),
    customerGrowth: path(ROOTS_DASHBOARD, '/report/customer-growth'),
    woltDrive: path(ROOTS_DASHBOARD, '/report/wolt-drive'),
    waitingTime: path(ROOTS_DASHBOARD, '/report/waiting-time'),
  },
  onlineStore: {
    root: path(ROOTS_DASHBOARD, '/online-store'),
    quickStartGuide: path(ROOTS_DASHBOARD, '/online-store/quick-start-guide'),
    widget: path(ROOTS_DASHBOARD, '/online-store/widget'),
    editWidget: path(ROOTS_DASHBOARD, '/online-store/widget/edit'),
    qrOrdering: path(ROOTS_DASHBOARD, '/online-store/qr-ordering'),
    setupQR: (id: string, step?: number) =>
      path(ROOTS_DASHBOARD, `/online-store/qr-ordering/${id}/setup${step ? `?step=${step}` : ''}`),
    developerTools: path(ROOTS_DASHBOARD, '/online-store/developer-tools'),
  },
  tablePlan: {
    root: path(ROOTS_DASHBOARD, '/table-plan'),
  },
  orderDisplay: {
    root: path(ROOTS_DASHBOARD, '/order-display'),
    setting: path(ROOTS_DASHBOARD, '/order-display/setting'),
  },
  payment: {
    root: path(ROOTS_DASHBOARD, '/payment'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    // BUSINESS
    business: path(ROOTS_DASHBOARD, '/settings/business'),
    newLocation: path(ROOTS_DASHBOARD, '/settings/business/location/new'),
    editLocation: (locationId: string) =>
      path(ROOTS_DASHBOARD, `/settings/business/location/${locationId}/edit`),
    // PREFERENCES
    preferences: path(ROOTS_DASHBOARD, '/settings/preferences'),
    // ACCOUNT
    account: path(ROOTS_DASHBOARD, '/settings/account'),
    // ACCOUNTANT KEY
    accountantKey: path(ROOTS_DASHBOARD, '/settings/accountant-key'),
    newAccountantKey: path(ROOTS_DASHBOARD, '/settings/accountant-key/new'),
    editAccountantKey: (keyId: string) =>
      path(ROOTS_DASHBOARD, `/settings/accountant-key/${keyId}/edit`),
    newKey: path(ROOTS_DASHBOARD, '/settings/accountant-key/new'),
    // FULFILLMENT
    fulfillment: path(ROOTS_DASHBOARD, '/settings/fulfillment'),
    pickupSetting: (branchId: string) =>
      path(ROOTS_DASHBOARD, `/settings/fulfillment/${branchId}/setting-pickup`),
    deliverySetting: (branchId: string) =>
      path(ROOTS_DASHBOARD, `/settings/fulfillment/${branchId}/setting-delivery`),
    newShipping: path(ROOTS_DASHBOARD, '/settings/fulfillment/shipping/new'),
    // BILLING
    billing: path(ROOTS_DASHBOARD, '/settings/billing'),
    // TAXES
    taxes: path(ROOTS_DASHBOARD, '/settings/taxes'),
    taxesNew: path(ROOTS_DASHBOARD, '/settings/taxes/new'),
    taxesEdit: (taxId: string) => path(ROOTS_DASHBOARD, `/settings/taxes/${taxId}/edit`),
    // NOTIFICATION
    notification: path(ROOTS_DASHBOARD, '/settings/notification'),
    // FILES
    files: path(ROOTS_DASHBOARD, '/settings/files'),
    // LEGACY
    newUser: path(ROOTS_DASHBOARD, '/settings/new-user'),
    teamManagement: path(ROOTS_DASHBOARD, '/settings/team-management'),
  },
  teamManagement: {
    root: path(ROOTS_DASHBOARD, '/team-management'),
    // TEAM MEMBER
    members: path(ROOTS_DASHBOARD, '/team-management/members'),
    membersAdd: path(ROOTS_DASHBOARD, `/team-management/members/add`),
    membersDetails: (memberId: string) =>
      path(ROOTS_DASHBOARD, `/team-management/members/${memberId}/details`),
    membersEditPersonalInformation: (roleId: string) =>
      path(ROOTS_DASHBOARD, `/team-management/members/${roleId}/details/personal-information`),
    membersEditAccess: (roleId: string) =>
      path(ROOTS_DASHBOARD, `/team-management/members/${roleId}/details/access`),
    membersEditPermission: (roleId: string) =>
      path(ROOTS_DASHBOARD, `/team-management/members/${roleId}/details/permission`),
    // ROLE
    roleDetails: (roleId: string) =>
      path(ROOTS_DASHBOARD, `/team-management/role/${roleId}/details`),
    role: path(ROOTS_DASHBOARD, '/team-management/role'),
    roleNew: path(ROOTS_DASHBOARD, '/team-management/role/new'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    dashboard: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    customers: path(ROOTS_DASHBOARD, '/customers'),
    integration: path(ROOTS_DASHBOARD, '/integration'),
    payment: path(ROOTS_DASHBOARD, '/payment'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
