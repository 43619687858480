import {
  CartItemOrder,
  CartItemOrderSimple,
  OutputSide,
  CreateOrderFormProps,
} from 'src/validations/orders/create-order';
import { CouponTypeClass } from 'src/@types/v2/coupon';
import { decideDefaultVariables, getSingleData } from './variableProcessor';
import { inferExtras, inferVariables } from './infers';

export const getVariationPrice = (output: OutputSide) => {
  if (!output._id) return 0;
  if (!output._data.variations || output._data.variations.length === 0)
    return output._data.base_price;

  return (
    output._data.variations.find((variation: any) => {
      const variants = variation.variants.map((variant: any) => ({
        variable: variant.variable._id,
        attributes: variant.attribute._id,
      }));

      const matches = variants.filter(
        (variant: any) =>
          output.variables.find(
            (outputVariant: any) =>
              outputVariant.variable === variant.variable &&
              outputVariant.attribute === variant.attributes
          ) !== undefined
      );

      return matches.length === variants.length;
    })?.base_price || 0
  );
};

export const calcPricing = (item: CartItemOrder, ignoreCustomPricing = false): number => {
  const calculatePrice = (output: OutputSide) => {
    if (!output._id) return 0;

    const getExtrasPrice = () => {
      const freeExtras = determineFreeExtras(output);

      return output.extras
        .map((extras) => {
          const extras_data = output._data.extras.find((x: any) => x._id === extras._id);
          return extras.items
            .map((extra: any) => {
              const extra_data = extras_data.items.find((t: any) => t._id === extra._id);

              if (
                !extra_data.variant_pricing ||
                !extra_data.variants ||
                extra_data.variants.length === 0
              )
                return (
                  extra_data.price * extra.quantity - (extra_data.include ? extra_data.price : 0)
                );

              const variator = output.variables.find(
                (vrbl) => vrbl.variable === extra_data.variants[0].variant_item.variable
              )?.attribute;

              const extra_variant = extra_data.variants.find(
                (v: any) => v.variant_item._id === variator
              );

              if (!extra_variant) return 0;

              const includedDiscount = extra_data.include ? extra_variant.price : 0;

              const freeExtrasDiscount =
                extra_variant.price * freeExtras.filter((id) => extra._id === id).length;

              const finalPrice =
                extra_variant.price * extra.quantity - includedDiscount - freeExtrasDiscount;

              return finalPrice;
            })
            .reduce((prev: number, curr: number) => prev + curr, 0);
        })
        .reduce((prev, curr) => prev + curr, 0);
    };
    return getVariationPrice(output) + getExtrasPrice();
  };

  if (item.custom_price && !ignoreCustomPricing) return item.price * item.quantity;

  if (item.split_mode || item.type === 'split') {
    const left = () => calculatePrice(item.output.left);
    const right = () => calculatePrice(item.output.right);
    const split = () => {
      if (left() === 0 || right() === 0) return 0;
      return Math.round(left() / 2) + Math.round(right() / 2);
    };
    const final = () => {
      const unitPrice = split();
      const rounding = Math.round(unitPrice);
      const timesQuantity = rounding * item.quantity;
      return timesQuantity;
    };

    return final();
  }
  if (item.output?.deals && item.output.deals?.length > 0) {
    return calculateDeals(item);
  }
  return calculatePrice(item.output) * item.quantity;
};

export const calculateSplitPrice = (item: CartItemOrder) => {
  const left = () => calcPricing(item.output.left);
  const right = () => calcPricing(item.output.right);
  const split = () => {
    if (left() === 0 || right() === 0) return 0;
    return Math.round(left() / 2) + Math.round(right() / 2);
  };
  const final = () => {
    const unitPrice = split();
    const rounding = Math.round(unitPrice);
    const timesQuantity = rounding * item.quantity;
    return timesQuantity;
  };

  return final();
};

export const getDiscountedPrice = (data: any) => {
  if (typeof data !== 'object' || !data) {
    return 0;
  }

  let productPrice = 0;
  const defaultVariables = decideDefaultVariables(data);

  if (defaultVariables) {
    const variablesData = getSingleData(data);
    const formatedProduct = {
      _id: data._id,
      _data: data,
      variables: inferVariables(variablesData, defaultVariables),
      extras: inferExtras(data),
    };
    productPrice = getVariationPrice(formatedProduct);
  }

  if (data.available_variations?.length > 0) {
    const variationed = data.available_variations.find((available_variation: any) => {
      const selectedVariation =
        available_variation.variants.filter((variant: any) => {
          const shouldBeLike = defaultVariables?.find(
            (defaultVariable: any) => defaultVariable.variable === variant.variant
          );
          return shouldBeLike?.attribute === variant.attribute;
        }).length === available_variation.variants.length;

      return selectedVariation;
    });

    if (!variationed) {
      return 0;
    }

    if (variationed.use_product_price) {
      return productPrice;
    }

    if (variationed.additional_price) {
      const { type, value } = variationed.additional_price;
      return calculateDiscount(type, value, productPrice);
    }
  }

  if (data.use_product_price) {
    return productPrice;
  }

  if (data.additional_price) {
    const { type, value } = data.additional_price;
    return calculateDiscount(type, value, productPrice);
  }

  return 0;
};

const calculateDeals = (item: CartItemOrderSimple) => {
  if (!item.output.deals || item.output.deals.length === 0) {
    return 0;
  }
  if (item.output._data.deals_rules && item.output._data.deals_rules.length > 0) {
    const data = item.output._data.deals_rules.map((rule: any) => {
      if (rule.type === 'highest_price') {
        const selectedMenu = rule.rule.selected_menu;

        const sorted = item.output._data.menus.map(
          (menu: any) => item.output.deals?.find((dealMenu) => dealMenu.menu_id === menu._id)
        );

        const pricePerSelectedMenu = selectedMenu.map((menuIndex: any) => {
          if (!item.output.deals) return 0;

          const menu = sorted[menuIndex - 1];
          if (!menu) return 0;
          if (menu.type === 'simple') return menu._data.base_price;
          if (menu.type !== 'variation_extras') return 0;

          if (!menu.extra_data || !menu.extra_data.left) return 0;

          let price = calcPricing({
            split_mode: false,
            output: menu.extra_data.left,
            quantity: 1,
          });

          if (menu.split_mode) {
            const rightProductPrice = calcPricing({
              split_mode: false,
              output: menu.extra_data.right,
              quantity: 1,
            });

            const halfLeftPrice = price / 2;
            const halfRightPrice = rightProductPrice / 2;
            price = halfLeftPrice + halfRightPrice;
          }

          return price;
        });

        const priceForSelectedMenu = pricePerSelectedMenu.sort((a: number, b: number) => a - b)[
          pricePerSelectedMenu.length - 1
        ];

        const discount = rule.rule.discount_for_unselected_menu.value;

        const unselectedMenu: any = [];
        if (item.output.deals) {
          sorted.forEach((menu: any, index: number) => {
            if (!selectedMenu.includes(index + 1)) {
              unselectedMenu.push(menu);
            }
          });
        }

        const pricePerUnselectedMenu = unselectedMenu.map((menu: any) => {
          if (!menu) return console.log('menu not found');
          if (menu.type === 'simple') return menu._data.base_price;
          if (!['variation_extras', 'variation'].includes(menu.type))
            return console.log('menu type not supported');
          if (!menu.extra_data || !menu.extra_data.left)
            return console.log('menu does not have extra data');
          const price =
            menu.extra_data &&
            calcPricing({
              split_mode: false,
              output: menu.extra_data.left,
              quantity: 1,
            });
          return price;
        });

        const priceForUnselectedMenu = pricePerUnselectedMenu
          .map((price: number) => price - (price * discount) / 100)
          .reduce((next: number, prev: number) => next + prev, 0);

        return priceForSelectedMenu + priceForUnselectedMenu;
      }
      return null;
    });

    if (!data.includes(NaN)) {
      return data.length > 0 ? data.reduce((prev: number, next: number) => prev + next, 0) : 0;
    }
  }

  const extraPrices = item.output.deals.map((deal) => {
    if (deal.extra_data) {
      if (deal.split_mode) {
        const splitPrice = calcPricing({
          split_mode: true,
          output: {
            left: deal.extra_data.left,
            right: deal.extra_data.right,
          },
          quantity: 1,
        });

        const leftVariationPrice = getVariationPrice(deal.extra_data.left);
        const rightVariationPrice = getVariationPrice(deal.extra_data.right);

        const leftPrice = leftVariationPrice / 2;
        const rightPrice = rightVariationPrice / 2;

        return splitPrice - (leftPrice + rightPrice);
      }

      const totalPrice = calcPricing({
        split_mode: false,
        output: deal.extra_data.left,
        quantity: 1,
      });

      const variantPrice = getVariationPrice(deal.extra_data.left);

      return totalPrice - variantPrice;
    }
    return 0;
  });

  // Custom price on each items
  const additionalPrice = item.output.deals
    .map((menu: any) => {
      if (menu.splitMode) {
        const leftDiscountedPrice = getDiscountedPrice(menu.extra_data.left._data) / 2;
        const rightDiscountedPrice = getDiscountedPrice(menu.extra_data.right._data) / 2;
        return leftDiscountedPrice + rightDiscountedPrice;
      }
      return getDiscountedPrice(menu._data);
    })
    .reduce((prev: any, next: any) => prev + next, 0);

  const totalExtraPrice = Math.max(
    extraPrices.reduce((prev: number, next: number) => prev + next, 0),
    0
  );

  const unitPrice = Math.max(item.output._data.base_price + totalExtraPrice + additionalPrice);

  return unitPrice * item.quantity;
};

export const determineFreeExtras = (output: OutputSide) => {
  if (!output._data.free_extras) return [];
  return output.extras
    .map((group) => {
      const groupData = output._data.extras.find((g: any) => g._id === group._id);
      return group.items
        .map((item: any) => {
          const itemData = groupData.items.find((s: any) => s._id === item._id);

          const variator = output.variables.find(
            (vrbl) => vrbl.variable === itemData.variants[0].variant_item.variable
          )?.attribute;

          const currentPrice =
            itemData.variants.find((v: any) => v.variant_item._id === variator)?.price || 0;

          const isActuallyFree = currentPrice === 0;

          return {
            _id: item._id,
            weight: isActuallyFree ? 0 : (!itemData.include ? 1 : 0) + (item.quantity > 1 ? 1 : 0),
            timestamp: item.timestamp,
          };
        })
        .flat();
    })
    .flat()
    .filter((item) => item.weight)
    .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
    .map((item) => Array(item.weight).fill(item._id))
    .flat()
    .slice(0, output._data.free_extras);
};

const calculateDiscount = (type: string, value: number, price: number) => {
  if (type === 'percent') {
    return Math.round((value / 100) * price);
  }
  if (type === 'percentage') {
    return Math.round(((100 - value) / 100) * price);
  }
  if (type === 'price') {
    return Math.round(price - value);
  }
  if (type === 'fixed') {
    return value;
  }
  return 0;
};

export const calcCouponDiscount = (
  items: CartItemOrder[],
  coupon: CouponTypeClass | null,
  order_method?: CreateOrderFormProps['order_method'],
  custom_discount?: CreateOrderFormProps['custom_discount']
) => {
  if (!coupon) {
    if (!custom_discount?.active) return 0;

    const { type, value } = custom_discount;

    if (type === 'percentage') {
      return items.reduce((prev, next) => prev + next.price * next.quantity, 0) * (value / 100);
    }
    if (type === 'fixed') {
      return value;
    }

    return 0;
  }

  const coupon_type = coupon;
  const { type, details } = coupon_type;

  let discountTotal = 0;

  items.forEach((item) => {
    if (type === 'product_category') {
      const { target } = details;
      const { type: curType, value } = details.amount;

      if (
        !order_method ||
        !details.order_method ||
        details.order_method === 'all' ||
        details.order_method === order_method
      ) {
        if (target.type === 'category' && target.categories) {
          const { categories } = target;

          if (
            item.type !== 'split' &&
            item.output._data.categories &&
            item.output._data.categories.filter((cat: any) =>
              categories.includes(cat.category?._id)
            ).length > 0
          ) {
            const discount = calculateDiscount(curType, value, calcPricing(item)) || 0;

            discountTotal += discount;

            return {
              ...item,
              discount_total: discount || 0,
            };
          }
          if (
            item.type === 'split' &&
            item.output.left._data.categories.filter((cat: any) =>
              categories.includes(cat.category?._id)
            ).length > 0 &&
            item.output.right._data.categories.filter((cat: any) =>
              categories.includes(cat.category?._id)
            ).length > 0
          ) {
            const discount = calculateDiscount(curType, value, calcPricing(item)) || 0;

            discountTotal += discount;

            return {
              ...item,
              discount_total: discount || 0,
            };
          }

          return item;
        }
        if (target.type === 'product' && target.products) {
          const { products } = target;

          if (
            item.type !== 'split' &&
            item.output._data._id &&
            products.some((product) => product.product === item.output._data._id)
          ) {
            const discount = calculateDiscount(curType, value, calcPricing(item)) || 0;

            discountTotal += discount;

            return {
              ...item,
              discount_total: discount || 0,
            };
          }
        }
        return item;
      }
      return item;
    }
    if (type === 'order_total') {
      const { type: curType, value } = details.amount;
      const discount = calculateDiscount(curType, value, calcPricing(item)) || 0;

      discountTotal += discount;

      return {
        ...item,
        discount_total: discount || 0,
      };
    }
    return item;
  });

  return discountTotal;
};
