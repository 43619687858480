import { VariableType } from 'src/validations/orders/create-order';

/* FUNC: to get the initial template values with the included extras being pre-selected */
export const inferExtras = (data: any) => {
  if (!data) return [];

  return data.extras.map((igrd: any) => ({
    _id: igrd._id,
    items: igrd.items
      .filter((itm: any) => itm.include)
      .map((itm: any) => ({
        _id: itm._id,
        doubled: false,
        timestamp: new Date().getTime(),
        quantity: 1,
      })),
  }));
};

/* FUNC: to get the initial template values and revalidate the preservation of previous values on pizza changes */
export const inferVariables = (
  variablesData: any[],
  preserve: VariableType[],
  reselect: (groupId: string, value: string) => void = () => null
): VariableType[] => {
  const result: VariableType[] = [];

  if (!variablesData) return result;

  for (let i = 0; i < preserve.length; i += 1) {
    const vrbl = variablesData.find((v: any) => v.variable._id === preserve[i].variable);

    const handleReselect = () => {
      const relatedVariables = variablesData.find(
        (vrbl2: any) => vrbl2.variable._id === vrbl.variable._id
      );

      if (!relatedVariables || !relatedVariables.attributes.length) return '';

      const value = relatedVariables.attributes.find(
        (attr: any) =>
          !result
            .filter((v: VariableType) => v.variable !== vrbl.variable._id)
            .find((v: VariableType) =>
              attr.antiVariables.find(
                (anti: VariableType) =>
                  v.variable === anti.variable && v.attribute === anti.attribute
              )
            )
      );

      if (!value) return '';

      reselect(vrbl.variable._id, value.attribute._id);
      return value;
    };

    result[i] = {
      variable: vrbl.variable._id,
      attribute: (() => {
        // Check if the variable is preserved
        const preservedAttribute =
          preserve.find((v: VariableType) => v.variable === vrbl.variable._id)?.attribute || '';
        if (!preservedAttribute) return handleReselect();

        // Check if the preserved attribute is available in the new pizza
        const attributeData = vrbl.attributes.find(
          (attr: any) => attr.attribute._id === preservedAttribute
        );
        if (!attributeData) return handleReselect();

        // Check if the preserved attribute is not meeting it's antiVariables
        const isInactive =
          result.filter((x) =>
            attributeData.antiVariables.find(
              (y: VariableType) => y.variable === x.variable && y.attribute === x.attribute
            )
          ).length > 0;
        if (isInactive) return handleReselect();

        return preservedAttribute;
      })(),
    };
  }

  return result;
};
