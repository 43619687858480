import { createSlice } from '@reduxjs/toolkit';
// utils
import { useSelector } from 'react-redux';
import {
  RestaurantTagsState,
  RestaurantCategoriesState,
  RestaurantVariablesState,
  RestaurantVariableState,
  RestaurantIngredientsState,
  RestaurantProductsState,
  RestaurantDealsState,
  RestaurantCouponsState,
  RestaurantPromotionsState,
  TagsType,
  BranchType,
} from 'src/@types/restaurant';
import { ProductType } from 'src/@types/v2/products';

// ----------------------------------------------------------------------

type RestaurantStateType = {
  isLoading: boolean;
  error: boolean;
  tags: RestaurantTagsState;
  categories: RestaurantCategoriesState;
  variables: RestaurantVariablesState;
  variable: RestaurantVariableState;
  ingredients: RestaurantIngredientsState;
  products: RestaurantProductsState;
  coupons: RestaurantCouponsState;
  deals: RestaurantDealsState;
  promotions: RestaurantPromotionsState;
  product: {
    data: ProductType | null;
  };
  reports: {
    orderType: any[];
    summary: any[];
    topCustomer: any;
    topProduct: any;
    sales: any;
    orderTime: any;
  };
  tag: TagsType | null;
  sortBy: string | null;
  filters: {
    gender: string[];
    category: string;
    colors: string[];
    priceRange: string;
    rating: string;
  };
  discount: any;
  branches: {
    data: BranchType[];
  };
};

const initialState: RestaurantStateType = {
  isLoading: false,
  error: false,
  tags: {
    sortBy: null,
    data: [],
  },
  variables: {
    sortBy: null,
    data: [],
  },
  variable: {
    sortBy: null,
    data: [],
  },
  product: {
    data: null,
  },
  products: {
    sortBy: null,
    data: [],
    filters: {
      priceAbove: null,
      priceBelow: null,
      available: null,
    },
  },
  categories: {
    sortBy: null,
    data: [],
  },
  ingredients: {
    sortBy: null,
    data: [],
  },
  coupons: {
    sortBy: null,
    data: [],
  },
  deals: {
    sortBy: null,
    data: [],
  },
  discount: {
    sortBy: null,
    data: [],
  },
  promotions: {
    sortBy: null,
    data: [],
  },
  reports: {
    orderType: [],
    summary: [],
    topCustomer: [],
    topProduct: [],
    sales: [],
    orderTime: [],
  },
  tag: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  branches: {
    data: [],
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: RestaurantStateType) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state: RestaurantStateType) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state: RestaurantStateType, action: { payload: boolean }) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    setProductData(state: RestaurantStateType, action: { payload: any }) {
      state.products.data = action.payload;
      state.isLoading = false;
    },

    // get Product
    setCurrentProduct(state: RestaurantStateType, action: { payload: any }) {
      state.product = action.payload;
      state.isLoading = false;
    },

    // GET TAGS
    getTagsSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.tags.data = action.payload;
    },

    // GET TAGS
    getTagSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.tags.data = action.payload;
    },

    // DELETE TAGS
    deleteTagSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.tags.data = action.payload;
    },
    // ADD TAGS
    addTagSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.tags.data.push(action.payload);
    },

    // UPDATE TAGS
    updateTagSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
    },

    //  SORT & FILTER tags
    sortByTag(state: RestaurantStateType, action: { payload: any }) {
      state.tags.sortBy = action.payload;
    },

    filterTag(state: RestaurantStateType, action: { payload: any }) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // GET  VARIABLES
    getVariablesSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.variables.data = action.payload;
      state.variable.data = action.payload;
    },

    // ADD VARIABLE
    addVariableSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.variables.data = [...state.variables.data, action.payload];
    },

    // UPDATE VARIABLE
    updateVariableSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.variables.data[action.payload.index] = action.payload.data;
    },

    // GET COUPONS
    getCouponsSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.coupons.data = action.payload;
    },

    //  SORT & FILTER tags
    sortByVariable(state: RestaurantStateType, action: { payload: any }) {
      state.variables.sortBy = action.payload;
    },
    // GET CATEGORY
    setCategories(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.categories.data = action.payload;
    },
    //  SORT & FILTER CATEGORY
    sortByCategory(state: RestaurantStateType, action: { payload: any }) {
      state.categories.sortBy = action.payload;
    },
    // UPDATE CATEGORY
    updateCategorySuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
    },
    // GET INGREDIENT
    getIngredientsSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.ingredients.data = action.payload;
    },
    //  SORT & FILTER INGREDIENT
    sortByIngredient(state: RestaurantStateType, action: { payload: any }) {
      state.ingredients.sortBy = action.payload;
    },
    // UPDATE INGREDIENT
    updateIngredientSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.ingredients.data = action.payload;
    },
    // ADD INGREDIENT
    addIngredientsSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.ingredients.data.push(action.payload);
    },
    // GET DEALS
    setDealsData(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.deals.data = action.payload;
    },

    // MERGE DEALS
    mergeDealsSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.deals.data = [...state.deals.data, action.payload];
    },
    //  SORT & FILTER deals
    sortByDeals(state: RestaurantStateType, action: { payload: any }) {
      state.deals.sortBy = action.payload;
    },
    //  SORT & FILTER product
    sortByProduct(state: RestaurantStateType, action: { payload: any }) {
      state.products.sortBy = action.payload;
    },
    filterProduct(state: RestaurantStateType, action: { payload: any }) {
      state.products.filters.priceBelow = action.payload.priceBelow;
      state.products.filters.priceAbove = action.payload.priceAbove;
      state.products.filters.available = action.payload.available;
    },
    //  SORT & FILTER PROMOTIONS
    sortByPromotion(state: RestaurantStateType, action: { payload: any }) {
      state.deals.sortBy = action.payload;
    },
    // GET PROMOTIONS
    getPromotionsSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.promotions.data = action.payload;
    },
    // GET Reports
    getReportsOrderTypesSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.reports.orderType = action.payload;
    },
    // GET Reports
    getReportsSummarySuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.reports.summary = action.payload;
    },
    // GET Reports
    getReportsTopCustomersSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.reports.topCustomer = action.payload;
    },
    // GET Reports
    getReportsTopProductsSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.reports.topProduct = action.payload;
    },
    // GET Reports
    getReportsSalesSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.reports.sales = action.payload;
    },
    // GET Reports
    getOrderTimesSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.reports.orderTime = action.payload;
    },
    // GET DISCOUNTS
    getDiscountsSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.discount.data = action.payload;
    },
    // ADD DISCOUNT
    addDiscountSuccess(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.discount.data.push(action.payload);
    },

    // GET BRANCHES
    setBranches(state: RestaurantStateType, action: { payload: any }) {
      state.isLoading = false;
      state.branches.data = action.payload;
    },
  },
});

export const {
  startLoading,
  hasError,
  setProductData,
  getTagSuccess,
  getTagsSuccess,
  deleteTagSuccess,
  sortByTag,
  filterTag,
  getVariablesSuccess,
  sortByVariable,
  setCategories,
  sortByCategory,
  updateCategorySuccess,
  getIngredientsSuccess,
  addIngredientsSuccess,
  sortByIngredient,
  updateIngredientSuccess,
  sortByDeals,
  mergeDealsSuccess,
  sortByPromotion,
  sortByProduct,
  filterProduct,
  setDealsData,
  getCouponsSuccess,
  getPromotionsSuccess,
  getReportsSummarySuccess,
  getOrderTimesSuccess,
  getReportsOrderTypesSuccess,
  getReportsSalesSuccess,
  getReportsTopCustomersSuccess,
  getReportsTopProductsSuccess,
  setCurrentProduct,
  getDiscountsSuccess,
  addDiscountSuccess,
  setBranches,
} = slice.actions;

export const useRestaurant = (): RestaurantStateType =>
  useSelector((RootState: { restaurant: RestaurantStateType }) => RootState.restaurant);

export default slice.reducer;
