import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CompanyOrderSummaryType } from 'src/@types/v2/customers/company-details';
import { CompanyType } from 'src/@types/v2/customers/list';
import { OrderType } from 'src/@types/v2/orders/list';

type CompanyDetailsStateType = {
  error: boolean;
  loading: {
    activation: boolean;
  };
  company: {
    data: CompanyType;
    isLoading: boolean;
  };
  orderHistory: {
    data: OrderType[];
    pagination: {
      page: number;
      limit: number;
      total: number;
      hasMoreResults: boolean;
    };
    isLoading: boolean;
    mergeLoading: boolean;
  };
  orderSummary: {
    data: CompanyOrderSummaryType;
    isLoading: boolean;
  };
  bulkOrder: {
    isLoading: boolean;
  };
};

const initialState: CompanyDetailsStateType = {
  error: false,
  loading: {
    activation: false,
  },
  company: {
    data: {} as CompanyType,
    isLoading: true,
  },
  orderHistory: {
    data: [] as OrderType[],
    pagination: {
      page: 1,
      limit: 25,
      total: 0,
      hasMoreResults: false,
    },
    isLoading: true,
    mergeLoading: true,
  },
  orderSummary: {
    data: {} as CompanyOrderSummaryType,
    isLoading: true,
  },
  bulkOrder: {
    isLoading: false,
  },
};

// ----------------------------------------------------------------------

const slice = createSlice({
  name: 'company-details',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      switch (action.payload) {
        case 'company':
          state.company.isLoading = true;
          break;
        case 'order-summary':
          state.orderSummary.isLoading = true;
          break;
        case 'order':
          state.orderHistory.isLoading = true;
          break;
        case 'order-merge':
          state.orderHistory.mergeLoading = true;
          break;
        case 'bulk-order':
          state.bulkOrder.isLoading = true;
          break;
        case 'activation':
          state.loading.activation = true;
          break;
        default:
          break;
      }
    },

    // STOP LOADING
    stopLoading(state, action) {
      switch (action.payload) {
        case 'company':
          state.company.isLoading = false;
          break;
        case 'order-summary':
          state.orderSummary.isLoading = false;
          break;
        case 'order':
          state.orderHistory.isLoading = false;
          break;
        case 'order-merge':
          state.orderHistory.mergeLoading = false;
          break;
        case 'bulk-order':
          state.bulkOrder.isLoading = false;
          break;
        case 'activation':
          state.loading.activation = false;
          break;
        default:
          break;
      }
    },

    // SET ORDER DETAIL DATA
    setCompany: (state, action) => {
      state.company.data = action.payload;
      state.company.isLoading = false;
    },

    // SET ORDER HISTORY
    setCompanyOrderHistory(state, action) {
      state.orderHistory.data = action.payload.data;
      state.orderHistory.pagination = action.payload.pagination;
      state.orderHistory.mergeLoading = false;
      state.orderHistory.isLoading = false;
    },

    // SET ORDER SUMMARY
    setCompanyOrderSummary(state, action) {
      state.orderSummary.data = action.payload;
      state.orderSummary.isLoading = false;
    },
  },
});

export const useCompanyDetails = (): CompanyDetailsStateType =>
  useSelector((RootState: { companyDetails: CompanyDetailsStateType }) => RootState.companyDetails);

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  stopLoading,
  setCompany,
  setCompanyOrderHistory,
  setCompanyOrderSummary,
} = slice.actions;
